import './Sidebar.css'
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'
import { MdDashboard, MdOutlineAdminPanelSettings } from 'react-icons/md';
import { IoIosCall } from 'react-icons/io';
import { BiWifi } from 'react-icons/bi';
import { BsFillPeopleFill } from 'react-icons/bs';
import { SiGooglemaps } from 'react-icons/si';
import { FaRegStickyNote } from "react-icons/fa";
import { FaBars } from 'react-icons/fa';
import logo from '../components/img/logoColRecortada.png';
import { Link } from "react-router-dom";
import { RxLaptop} from 'react-icons/rx'
import { jwtDecode } from 'jwt-decode';


const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [temChamadaAtiva, setTemChamadaAtiva] = useState(false);
  const toggleSidebar = () => setIsOpen(!isOpen);

  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserRole(decodedToken.role); // 'role' é o campo do token que indica o papel do usuário
      } catch (error) {
        console.error('Erro ao decodificar o token: ', error);
      }
    }
  }, []);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserRole(decodedToken.role); // 'role' é o campo do token que indica o papel do usuário
      } catch (error) {
        console.error('Erro ao decodificar o token: ', error);
      }
    }

    // Verificar se tem chamada ativa no localStorage ao montar o componente
    const chamadaAtiva = localStorage.getItem('chamada') === 'true';
    setTemChamadaAtiva(chamadaAtiva);
  }, []);
  return (
    <div className="container-main">
      <motion.div
        className="sidebar"
        animate={{ width: isOpen ? "200px" : "37px" }}
        transition={{ duration: 0.001 }}
      >
        <div className="sidebar__top-section">
          <Link exact to='/' title="Logo">
            {isOpen && <img className="sidebar__logo" src={logo} alt="logo" />}
          </Link>
          <div className="sidebar__bars">
            <FaBars onClick={toggleSidebar} />
          </div>
        </div>
        <section className="routes link_text">
          <ul className="sidebar-nav">
            <li className={`sidebar-item ${isOpen ? "" : "sidebar-item-closed  sidebar-item__icon-closed"}`}>
              <Link exact to="/Painel_de_controle" title="Painel de controle">
                <MdDashboard className="sidebar-item__icon" />
                <span className="sidebar-item__text sidebar-item__link" style={{ color: '#3c3c3c', fontFamily: 'Arial' }}>Painel de controle</span>
                </Link>
              </li>
              <li className={`sidebar-item ${isOpen ? "" : "sidebar-item-closed sidebar-item__link sidebar-item__icon-closed"}`}>
                
                <Link exact to="/Administracao" title="Administração"><MdOutlineAdminPanelSettings exact to='/Administracao'  className="sidebar-item__icon"/>
                  <span className="sidebar-item__text sidebar-item__link" style={{color:'#3c3c3c', fontFamily:"sans-serif"}}>Administração</span>
                </Link>
              </li>
              <li className={`sidebar-item ${isOpen ? "" : "sidebar-item-closed sidebar-item__link sidebar-item__icon-closed"}`}>
                
                <Link exact to="/maquinas" title="Maquinas"><RxLaptop  className="sidebar-item__icon"/>
                  <span className="sidebar-item__text sidebar-item__link" style={{color:'#3c3c3c', fontFamily:"sans-serif"}} >Maquinas</span>
                </Link>
              </li>
              
              {userRole === 'admin' && ( 
              <li className={`sidebar-item ${isOpen ? "" : "sidebar-item-closed sidebar-item__link sidebar-item__icon-closed"}`}>
                
                <Link exact to="/chamadas" title="Chamadas"> <IoIosCall className="sidebar-item__icon"/>
                  <span className="sidebar-item__text sidebar-item__link" style={{color:'#3c3c3c', fontFamily:"sans-serif"}} >Chamadas</span>
                </Link>
              </li>)}
              {userRole === 'tecnico' && ( 
              <li className={`sidebar-item ${isOpen ? "" : "sidebar-item-closed sidebar-item__link sidebar-item__icon-closed"}`}>
                
                <Link exact to="/chamadasTecnicos" title="Chamadas">
    <div className="icon-container">
      <IoIosCall className="sidebar-item__icon"/>
      {temChamadaAtiva && <span className="chamada-ativa-indicador">!</span>}

    </div>
    <span className="sidebar-item__text sidebar-item__link" style={{color:'#3c3c3c', fontFamily:"sans-serif"}}>Chamadas</span>
  </Link>
              </li>)}
              <li className={`sidebar-item ${isOpen ? "" : "sidebar-item-closed sidebar-item__link sidebar-item__icon-closed"}`}>
                
                <Link exact to="/Tecnicos" title="Tecnicos"> <BsFillPeopleFill className="sidebar-item__icon"/>
                  <span className="sidebar-item__text sidebar-item__link" style={{color:'#3c3c3c', fontFamily:"sans-serif"}} >Tecnicos</span>
                </Link>
              </li>
              <li className={`sidebar-item ${isOpen ? "" : "sidebar-item-closed sidebar-item__link sidebar-item__icon-closed"}`}>
                
                <Link exact to="/Localizacao" title="Localização"><SiGooglemaps className="sidebar-item__icon"/>
                <span className="sidebar-item__text sidebar-item__link" style={{color:'#3c3c3c', fontFamily:"sans-serif"}} >Localização</span>
                </Link>
              </li>
              <li className={`sidebar-item ${isOpen ? "" : "sidebar-item-closed sidebar-item__link sidebar-item__icon-closed"}`}>
                <Link exact to="/EcoClub" title="EcoClub"> <FaRegStickyNote className="sidebar-item__icon "/>
                <span className="sidebar-item__text sidebar-item__link" style={{color:'#3c3c3c', fontFamily:"sans-serif"}} >EcoClub</span>
                </Link>
              </li>
              <li className={`sidebar-item ${isOpen ? "" : "sidebar-item-closed sidebar-item__link sidebar-item__icon-closed"}`}>
                <a href="https://drive.google.com/drive/folders/1KWEByqwdq6l8C8Di6gVQLYtxV0QysPLm?usp=sharing" target="_blank" title="EcoTV"> <BiWifi className="sidebar-item__icon "/>
                <span className="sidebar-item__text sidebar-item__link" style={{color:'#3c3c3c', fontFamily:"sans-serif"}} >EcoTV</span>
                </a>
              </li>
            </ul>
          </section>
        </motion.div>
        <main className={`content ${isOpen ? "open" : ""}`}>{children}</main> {/* aplica as páginas o padding de 200px quando a sidebar é aberta */}
      </div>
    );
  };
  
  export default Sidebar;

