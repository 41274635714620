import React from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header/Header";
import BarChart from "../../components/BarChart";
import BarChartClients from "../../components/BarChartClients";
import BarCharttwo from "../../components/BarChart copy";
import Piecesar from "../../components/GrafPizzaIdade";
import GrafPizzaGen from "../../components/GrafPizzaGen";
import GraficoCausas from "../../components/GrafPizzaCausas";
import { useState, useEffect } from "react";
import "./admijair.css";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { BsFillCheckCircleFill, BsFillExclamationCircleFill, BsBellFill, BsBellSlashFill } from "react-icons/bs";
import axios from 'axios';
import { Link } from "react-router-dom";


const Admijair = () => {  
    
  const [interacoes, setInteracoes] = useState([]);
  const [numeroInteracoes, setNumeroInteracoes] = useState(0);
  const [interacoesFinalizadas, setInteracoesFinalizadas] = useState(0); 
  const [interacoesInacabadas, setInteracoesInacabadas] = useState(0);
  const [clientes, setClientes] = useState([]);
  const [numeroClientes, setNumeroClientes] = useState(0);  

  const [machines, setMachines] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedCity, setSelectedCity] = useState(""); 
  const [filteredCities, setFilteredCities] = useState([]);
  const [isGeralSelected, setIsGeralSelected] = useState(false);


  
  
  async function fetchCEPfromCities(cityNames) {
    const cepPromises = cityNames.map(async (city) => {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${city}/json/`);
        if (!response.data.erro) {
          return {
            city: city,
            cep: response.data.cep,
          };
        } else {
          console.error(`CEP for ${city} not found`);
          return null;
        }
      } catch (error) {
        console.error(`Error fetching CEP information for ${city}: ${error.message}`);
        return null;
      }
    });

    const cepResults = await Promise.all(cepPromises);
    return cepResults.filter((result) => result !== null);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const interacoesResponse = await axios.get("https://www.repenseapi.com/api/v1/interacoes", {
          headers: {
            'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key real
          }
        });
        setInteracoes(interacoesResponse.data);

        const uniqueUsers = new Set(interacoesResponse.data.map(interacao => interacao.userId));
        setNumeroClientes(uniqueUsers.size);

        setNumeroInteracoes(interacoesResponse.data.length);

        const interacoesFinalizadasCount = interacoesResponse.data.filter((interacao) => interacao.status_final === 1).length;
        setInteracoesFinalizadas(interacoesFinalizadasCount);

        const interacoesInacabadasCount = interacoesResponse.data.filter((interacao) => interacao.status_final === 2).length;
        setInteracoesInacabadas(interacoesInacabadasCount);

        const clientesResponse = await axios.get("https://www.repenseapi.com.com/api/v1/usereco", {
          headers: {
            'api': process.env.REACT_APP_API_KEY// Substitua SUA_API_KEY_AQUI pela sua API Key real
          }
        });
        setClientes(clientesResponse.data);
       

        
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }

    fetchData();
  }, []);


  useEffect(() => {
    async function fetchInteracoes() {
      try {
        let apiUrl = "https://www.repenseapi.com/api/v1/interacoes";

        if ((selectedOption === "maquinas" && selectedCity) || isGeralSelected) {
          const selectedMachine = machines.find((machine) => machine.nome === selectedCity);
  
          if (selectedMachine || isGeralSelected) {
            const machineId = isGeralSelected ? null : selectedMachine.id;
  
            const response = await axios.get(apiUrl, {
              headers: {
                'api': process.env.REACT_APP_API_KEY// Substitua SUA_API_KEY_AQUI pela sua API Key real
              }
            });
  
            setInteracoes(response.data);
            setNumeroInteracoes(response.data.filter(
              (interacao) => !machineId || interacao.maquinaId === machineId
            ).length);
  
            const interacoesFinalizadas = response.data.filter(
              (interacao) => interacao.status_final === 1 && (!machineId || interacao.maquinaId === machineId)
            ).length;
            setInteracoesFinalizadas(interacoesFinalizadas);
  
            const interacoesInacabadas = response.data.filter(
              (interacao) => interacao.status_final === 2 && (!machineId || interacao.maquinaId === machineId)
            ).length;
            setInteracoesInacabadas(interacoesInacabadas);
          }

        {/*if (selectedOption === "cidade" && selectedCity) {
          
          const citiesWithCEP = await fetchCEPfromCities([selectedCity]);
          console.log(citiesWithCEP);

          if (citiesWithCEP.length > 0) {
            const selectedCityCEP = citiesWithCEP[0].cep;
            console.log(selectedCityCEP);

            
            const machinesWithSameCEP = machines.filter((machine) => machine.CEP === selectedCityCEP);
            const machineIds = machinesWithSameCEP.map((machine) => machine.id);

            
            const interacoesPromises = machineIds.map(async (machineId) => {
              const response = await axios.get(apiUrl, {
                params: { maquinaId: machineId },
              });

              return {
                machineId: machineId,
                interacoesCount: response.data.length,
              };
            });

            const interacoesCounts = await Promise.all(interacoesPromises);

            
          } else {
            
          }
        }*/}
       }
      } catch (error) {
        console.error("Erro ao buscar as interações: ", error);
      }
    }

    fetchInteracoes();
  }, [selectedOption, selectedCity, machines]);

  

  useEffect(() => {
    async function fetchCitiesFromCEP() {
      try {
        const machinesResponse = await axios.get(
          "https://www.repenseapi.com/api/v1/machine"
          , {
            headers: {
              'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key real
            }
          });

        const uniqueCities = Array.from(new Set(machinesResponse.data.map(machine => machine.CEP)));

        const cityPromises = uniqueCities.map(async (cep) => {
          const city = await fetchCityFromCEP(cep);
          return city;
        });
        const uniqueCitiesFromDB = Array.from(new Set(await Promise.all(cityPromises)));
        setFilteredCities(uniqueCitiesFromDB);
      } catch (error) {
        console.error("Erro ao buscar cidades a partir dos CEPs: ", error);
      }
    }

    async function fetchCityFromCEP(cep) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        if (!response.data.erro) {
          return response.data.localidade;
        } else {
          console.error(`CEP ${cep} não encontrado`);
          return "";
        }
      } catch (error) {
        console.error(`Erro ao buscar informações de CEP: ${error.message}`);
        return "";
      }
    }

    fetchCitiesFromCEP();
  }, []);

  useEffect(() => {
    async function fetchMachines() {
      try {
        const machinesResponse = await axios.get(
          "https://www.repenseapi.com/api/v1/machine", {
            headers: {
              'api': process.env.REACT_APP_API_KEY// Substitua SUA_API_KEY_AQUI pela sua API Key real
            }
          }
        );

        const machineData = machinesResponse.data;
        setMachines(machineData);
      } catch (error) {
        console.error("Erro ao buscar máquinas: ", error);
      }
    }

    fetchMachines();
  }, []);
  


    return (
        <div className="div-administração">
      <Sidebar />
      <div style={{ flex: '2' }}>
        <Header />
        <div className="nav_painel">
          <p className="titulo_painel_administracao"> Administração</p>

          <div className="navEndereco_administracao">
          <Link className="navHome_administracao" exact to="/Painel_de_controle" title="EcoTV"  style={{ color: "#197ED6" }} >Home</Link>
            <p className="navAtual_administracao" > &gt; </p>
            <p className="navAtual_administracao">Administração</p>
          </div>
          
          </div>
          <div className="dropdown-container-maquinas">
            
          
                <FormControl className="width_select" style={{ marginBottom: "20px" }}>
                  <p className="size_font">
                    <InputLabel htmlFor="filter-option" className="text_adm">Filtrar por:</InputLabel>
                  </p>
                  <select
                    className="width_select"
                    id="filter-option"
                    value={selectedOption}
                    onChange={(event) => {
                      setSelectedOption(event.target.value);
                      setSelectedCity(""); 
                    }}
                  >
                    <option value="">Selecione...</option>
                    {/*<option value="cidade">Cidades</option>*/}
                    <option value="maquinas">Máquinas</option>
                  </select>
              </FormControl>
          
              <FormControl className="width_select" style={{ marginBottom: "20px" }}>
                <InputLabel htmlFor="second-dropdown" className="text_adm2"></InputLabel>
                <select
                  id="second-dropdown"
                  className="width_select"
                  onChange={(event) => {
                    setIsGeralSelected(event.target.value === "Geral");
                    setSelectedCity(event.target.value);
                    
                  }}
                  disabled={!selectedOption}
                  value={selectedCity}
                >
                  <option value="">Selecione...</option>
                  {selectedOption === "cidade" ? (
                    filteredCities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))
                  ) : (
                    machines.map((machine) => (
                      <option key={machine.id} value={machine.nome}>
                        {machine.nome}
                        
                      </option>
                    ))
                    
                  )}
                  
                  <option value="Geral">Geral</option>
                </select>
              </FormControl>
            

              
                  </div>
        <div className="padding-administração">
         
            {selectedOption==="maquinas" &&  !isGeralSelected ?  (
              <>
              <div className="containersup_adm">
              <BsFillCheckCircleFill size={30} style={{ fill: '#139b60', position: "", marginRight: "80px", marginLeft:"20px", marginBottom: "45px" }} />
              <div>
                <p className="cardNumber_adm"> {interacoesFinalizadas} </p>
                <p className="txtCard_adm">Celulares Aceitos</p>
              </div>
            </div>

            <div className="containersup_adm">
              <BsFillCheckCircleFill size={30} style={{ fill: '#139b60', position: "", marginRight: "80px", marginLeft:"20px", marginBottom: "45px" }} />
              <div>
                <p className="cardNumber_adm"> {interacoesInacabadas}</p>
                <p className="txtCard_adm">Celulares Recusados</p>
              </div>
            </div>
            
            <div className="containersup_adm">
              <BsFillCheckCircleFill size={30} style={{ fill: '#139b60', position: "", marginRight: "80px", marginLeft:"20px", marginBottom: "45px" }} />
              <div>
                <p className="cardNumber_adm"> {numeroInteracoes}</p>
                <p className="txtCard_adm">Interações Totais</p>
              </div>
            </div>
            </>
            ):null}


            
            {(selectedOption!=="maquinas") || (selectedOption==="maquinas" && isGeralSelected) ?  (
              <>
            <div className="containersup_adm">
              <BsFillCheckCircleFill size={30} style={{ fill: '#139b60', position: "", marginRight: "80px", marginLeft:"20px", marginBottom: "45px" }} />
              <div>
                <p className="cardNumber_adm"> {interacoesFinalizadas} </p>
                <p className="txtCard_adm">Celulares Aceitos</p>
              </div>
            </div>

            <div className="containersup_adm">
              <BsFillCheckCircleFill size={30} style={{ fill: '#139b60', position: "", marginRight: "80px", marginLeft:"20px", marginBottom: "45px" }} />
              <div>
                <p className="cardNumber_adm"> {interacoesInacabadas}</p>
                <p className="txtCard_adm">Celulares Recusados</p>
              </div>
            </div>
          
            <div className="containersup_adm">
              <BsFillCheckCircleFill size={30} style={{ fill: '#139b60', position: "", marginRight: "80px", marginLeft:"20px", marginBottom: "45px" }} />
              <div>
                <p className="cardNumber_adm"> {numeroInteracoes}</p>
                <p className="txtCard_adm">Interações Totais</p>
              </div>
            </div>

            <div className="containersup_adm">
              <BsFillCheckCircleFill size={30} style={{ fill: '#139b60', position: "", marginRight: "80px", marginLeft:"20px", marginBottom: "45px" }} />
              <div>
                <p className="cardNumber_adm"> {numeroClientes}</p>
                <p className="txtCard_adm">Usuários</p>
              </div>
            </div>
            

              </> ):null}

        </div>

            

          {/*<div className="conteudo_adm">
            <div className="graf-adm"> 
              <h2>Causas apoiadas durante a semana</h2>
              <BarCharttwo data={} />   
            </div>
            <div className="graf-adm" >
            <h2>Recebimento de celulares por dia</h2>
              <BarChart data={}/>
            </div> 
          </div>*/}
          
          <div className="conteudo_adm">
            <div className="graf-adm"> 
              <h2>Total de ODS</h2>
              <GraficoCausas selectedMachine={selectedCity}/>  
            </div>
            <div className="graf-adm" >
            <h2>Gênero dos Usuários</h2>
              <GrafPizzaGen selectedMachine={selectedCity}/>
            </div> 
          </div>
          <div className="conteudo_adm">
            <div className="graf-adm"> 
              <h2>Faixa Etária De Utilização</h2>
              <Piecesar selectedMachine={selectedCity}/>  
            </div>
            <div className="graf-adm" >
              <h2>Porcentagem de Recebimento de Celulares</h2>
              <BarChartClients selectedMachine={selectedCity}/>
            </div> 
          </div>
          
        </div>
       
        
        

      </div>

    );
}

export default Admijair;