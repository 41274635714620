import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header/Header';
import '../Tecnicos/Tecnicos.css';
import { Link } from 'react-router-dom';

import axios from 'axios';
import Modal_tecnicos from '../CreateTec/modal_tecnicos';
import Modal_info_tec from '../CreateTec/modal_info_tec';
import { jwtDecode } from 'jwt-decode';


const Tecnicos = () => {
  const [tecnicoToEdit, setTecnicosToEdit] = useState(null);
  const [tecnicoToEditt, setTecnicosToEditt] = useState(null);
  const [tecnicos, setTecnicos] = useState([]);
  const [userEco, setUserEco] = useState([]);

  const [searchId, setSearchId] = useState('');
  const [filteredTecnicos, setFilteredTecnicos] = useState([]);
  
  const [showAlert, setShowAlert] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenn, setIsOpenn] = useState(false);
  const [isBackgroundOpaque, setIsBackgroundOpaque] = useState(true);
  const [userRole, setUserRole] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserRole(decodedToken.role); // 'role' é o campo do token que indica o papel do usuário
      } catch (error) {
        console.error('Erro ao decodificar o token: ', error);
      }
    }
  }, []);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      // Verifique se o clique não ocorreu dentro do dropdown
      if (!e.target.closest(".dropdown-container-Tecnicos")) {
        // Feche o dropdown definindo showMenu como false
        setShowMenu((prevShowMenu) => {
          const newShowMenu = [...prevShowMenu];
          newShowMenu.fill(false); // Fecha todos os dropdowns abertos
          return newShowMenu;
        });
      }
    };

    // Adicione o ouvinte de evento de clique ao documento
    document.addEventListener("click", handleDocumentClick);

    // Remova o ouvinte quando o componente for desmontado
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);


  useEffect(() => {
    async function fetchTecnicos() {
      try {
        const response = await axios.get('https://www.repenseapi.com/api/v1/tecnicos', {
          headers: {
            'api': process.env.REACT_APP_API_KEY  // Substitua SUA_API_KEY_AQUI pela sua API Key real
          }
        });
        const id=response.data.userEcoId;
        setTecnicos(response.data);
        const response2 = await axios.get(`https://www.repenseapi.com/api/v1/userEco/${response.data[0].userEcoId}`, {
          headers: {
            'api': process.env.REACT_APP_API_KEY  // Substitua SUA_API_KEY_AQUI pela sua API Key real
          }
        });
        setUserEco(response2.data);

        
 
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    }

    fetchTecnicos();
  }, []);
   const handleTelefoneChange = (e) => {
    const newTelefone = e.target.value;
    setTelefone(newTelefone);

    // Expressão regular para validar um número de telefone brasileiro
    const telefoneRegex = /^\(\d{2}\) \d{4,5}-\d{4}$/;

    if (!telefoneRegex.test(newTelefone)) {
      setTelefoneError('Telefone inválido');
    } else {
      setTelefoneError('');
    }
  };
 

  // useEffect(() => {
  //   const filtered = tecnicos.filter((tecnico) => tecnico.id.toString() === searchId);
  //   setFilteredTecnicos(filtered);
  // }, [tecnicos, searchId]);

  useEffect(() => {
    const filtered = tecnicos.filter((tecnico) => {
      const searchValue = searchId.toLowerCase();
      return (tecnico.id.toString().includes(searchValue) || 
              tecnico.nome.toLowerCase().includes(searchValue) || 
              tecnico.CEP.toString().includes(searchValue) ||
              tecnico.DDD.toString().includes(searchValue) ||
              tecnico.telefone.toString().includes(searchValue));
    });
   
    setFilteredTecnicos(filtered);
  }, [tecnicos, searchId]);

//////////////////
  const handleUpdateTecnico = (updatedTecnico) => {
    const updatedTecnicos = tecnicos.map((tecnico) =>
      tecnico.id === updatedTecnico.id ? updatedTecnico : tecnico
    );
    setTecnicos(updatedTecnicos);
  };

  const handleUpdateTecnicoo = (updatedTecnicoo) => {
    const updatedTecnicos = tecnicos.map((tecnico) =>
      tecnico.id === updatedTecnicoo.id ? updatedTecnicoo : tecnico
    );
    setTecnicos(updatedTecnicos);
  };

  const handleInfoClick = (tecnicoIuser) => {
    const tecnicoo = tecnicos.find((tecnico) => tecnico.user === tecnicoIuser);

    if (tecnicoo) {
      setTecnicosToEditt(tecnicoo);
      setIsOpenn(true);
    } else {
      console.error('Nenhum técnico encontrado com o ID fornecido.');
    }
  };

  const handleEditClick = (tecnicoId) => {
    const tecnico = tecnicos.find((tecnico) => tecnico.id === tecnicoId);
    setTecnicosToEdit(tecnico);
    setIsOpen(true);
  };

  const handleRegisterClick = () => {
    setIsOpen(true);
    setIsBackgroundOpaque(false);
  };

  const handleinfoclick_modal = (tecnicoId) => {
    const tecnico = tecnicos.find((tecnico) => tecnico.id === tecnicoId);
    setTecnicosToEditt(tecnico);
    setIsOpenn(true);
  };

  const handleModalClose = () => {
    setIsOpen(false);
    setIsBackgroundOpaque(true);
  };

  const handleModalClosee = () => {
    setIsOpenn(false);
    setIsBackgroundOpaque(true);
  };

  const [showMenu, setShowMenu] = useState([]);

  const toggleMenu = (index) => {
    const newShowMenu = [...showMenu];
    newShowMenu[index] = !newShowMenu[index];
  
    // Fechar todos os outros dropdowns abertos
    newShowMenu.forEach((menuOpen, i) => {
      if (i !== index && menuOpen) {
        newShowMenu[i] = false;
      }
    });
  
    setShowMenu(newShowMenu);
  };

  const handleDeleteTecnico = async (id) => {
    try {
      await axios.delete(`https://www.repenseapi.com/api/v1/tecnicos/${id}`, {
        headers: {
          'api': process.env.REACT_APP_API_KEY  // Substitua SUA_API_KEY_AQUI pela sua API Key real
        }
      });

      const updatedTecnicos = tecnicos.filter((tecnico) => tecnico.id !== id);
      setTecnicos(updatedTecnicos);
      setShowMenu((prevShowMenu) => {
        const newShowMenu = [...prevShowMenu];
        newShowMenu[id] = false;
        return newShowMenu;
      });

      setShowAlert(true);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error('Erro ao excluir técnico:', error);
    }
  };

  return (
    <div className="div-tecnicos">
      <Sidebar />

      <div style={{ flex: '1' }}>
        <Header />
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.9.3/dist/leaflet.css"
          integrity="sha256-kLaT2GOSpHechhsozzB+flnD+zUyjE2LlfWPgU04xyI="
          crossOrigin=""
        />
        <div className="padding-tecnicos">
          <div className="nav_Tecnicos">
            <p className="titulo_Tecnicos">Lista de técnicos</p>

            <div className="navEndereco_Tecnicos">
              <Link className="navHome_Tecnicos" exact to="/Painel_de_controle" title="EcoTV"  style={{ color: "#197ED6" }} >Home</Link>
              <p className="navAtual_Tecnicos">{' > '}</p>
              <p className="navAtual_Tecnicos">Técnicos</p>
            </div>
          </div>
          <div className="topCard_Tecnicos">
            <input
              type="text"
              placeholder="Pesquisar..."
              className="Pesq_Tecnicos"
              value={searchId}
              onChange={(e) => setSearchId(e.target.value)}
            />
            {userRole === 'admin' && (  // Aqui verificamos se o papel do usuário é 'admin'
            <Link to="/CreateTec" id="BtnSignUp_Tecnicos">
              Cadastrar
            </Link>
          )}     
          </div>
          <div className="Body_Tecicos">
            <table className="tableTecStyle">
              <thead className="theadTecn">
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>CEP</th>
                  <th>DDD</th>
                  <th>Telefone</th>
                  {userRole === 'admin' && (
                  <th>Ações</th>)}
                </tr>
              </thead>
              <tbody className="tbodyTecn">
                {(searchId === '' || !searchId)
                  ? tecnicos.slice(0, 13).map((tecnico) => (
                    
                    // tecnicos.slice(0, 13).map((user) => (
                      <tr key={tecnico.id} className="movie-container">
                        <td className="movie-img">{tecnico.id}</td>
                        <td className="perfil_Tecnicos">
                          <button
                            className="usuario-button"
                            onClick={() => {
                              handleInfoClick(tecnico.user);
                            }}
                          >
                            <p>{tecnico.nome}</p>
                          </button>
                        </td>
                        <td>{tecnico.CEP}</td>
                        <td>{tecnico.DDD}</td>
                        <td>{tecnico.telefone}</td>
                        {userRole === 'admin' && (
                        <td className="EngrenagemTec">
                          
                          <div className="dropdown-container-Tecnicos">
                          
                            <button
                              className="dropbtn"
                              onClick={() => toggleMenu(tecnico.id)}
                            >
                        
                            </button>
                          
                            <div
                              className={`dropdown-menu-EcoTV ${
                                showMenu[tecnico.id] ? 'show' : ''
                              }`}
                            >
                              <button
                                className="dropdown-item"
                                onClick={() => {
                                  handleRegisterClick();
                                  handleEditClick(tecnico.id);
                                }}
                              >
                                Editar
                              </button>
                              <button
                                className="dropdown-item delete-button"
                                onClick={() => handleDeleteTecnico(tecnico.id)}
                              >
                                Deletar
                              </button>
                              
                            </div>
                            
                          </div>
                        </td>
                        )}
                        
                      </tr>
                      
                    ))
                  : filteredTecnicos.map((tecnico) => (
                      <tr key={tecnico.id} className="movie-container">
                        <td className="movie-img">{tecnico.id}</td>
                        <td className="perfil_Tecnicos">{tecnico.nome}</td>
                        <td>{tecnico.CEP}</td>
                        <td>{tecnico.DDD}</td>
                        <td>{tecnico.telefone}</td>
                        {userRole === 'admin' && (
                        <td className="EngrenagemTec">
                          <div className="dropdown-container-Tecnicos">
                            <button
                              className="dropbtn"
                              onClick={() => toggleMenu(tecnico.id)}
                            >
                     
                            </button>
                            <div
                              className={`dropdown-menu-EcoTV ${
                                showMenu[tecnico.id] ? 'show' : ''
                              }`}
                            >
                              <button  className="dropdown-item"
                                onClick={() => {
                                  handleRegisterClick();
                                  handleEditClick(tecnico.id);
                                }}>Editar</button>
                              <button
                                className="dropdown-item delete-button"
                                onClick={() => handleDeleteTecnico(tecnico.id)}
                              >
                                Deletar
                              </button>
                            </div>
                          </div>
                        </td>
                        )}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isOpen && (
        <Modal_tecnicos
          isOpen={isOpen}
          onClose={handleModalClose}
          tecnicoToEdit={tecnicoToEdit}
          onUpdateTecnico={handleUpdateTecnico}
        />
      )}
      {isOpenn && (
        <Modal_info_tec
          isOpenn={isOpenn}
          onClosee={handleModalClosee}
          tecnicoToEditt={tecnicoToEditt}
          onUpdateTecnicoo={handleUpdateTecnicoo}
        />
      )}
    </div>
  );
};

export default Tecnicos;
