import React from 'react';
import './error404.css'
const Error = () => {
    return (
        <div className="error-container">
            <h1 className="error-code">404</h1>
            <p className="error-message">Oops! Página não encontrada.</p>
            <a href="/" className="error-home-link">Voltar para a página inicial</a>
        </div>
    );
};

export default Error;