import { ResponsivePie } from "@nivo/pie";
import axios from 'axios';
import { useState,useEffect  } from 'react';

  const Pie = ({ selectedMachine }) => {
    const [users, setUsers] = useState([]);
    const [numUsers, setNumUsers] = useState(0);
    const [numUsersMasc, setNumUsersMasc] = useState(0);
    const [numUsersFem, setNumUsersFem] = useState(0);
    const [numUsersOutros, setNumUsersOutros] = useState(0);

    useEffect(() => {
      async function fetchInteracoes() {
        try {
          let totenId = null;

          if (selectedMachine && selectedMachine !== "Geral") {
            const responseTotens = await axios.get(`https://www.repenseapi.com/api/v1/machine`, {
              headers: {
                'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key real
              }
            });
            const totenData = responseTotens.data.find(machine => machine.nome === selectedMachine);
            if (totenData) {
              totenId = totenData.id; 
            }
          }
  
          const responseInteracoes = await axios.get("https://www.repenseapi.com/api/v1/interacoes", {
            headers: {
              'api': process.env.REACT_APP_API_KEY// Substitua SUA_API_KEY_AQUI pela sua API Key real
            }
          });
          let interacoes = responseInteracoes.data;
          if (totenId) {
            interacoes = interacoes.filter(interacao => interacao.maquinaId === totenId);
          }
  
          const userIDs = new Set(interacoes.map(interacao => interacao.userId));
          const responseUsers = await axios.get("https://www.repenseapi.com/api/v1/users", {
            headers: {
              'api': process.env.REACT_APP_API_KEY// Substitua SUA_API_KEY_AQUI pela sua API Key real
            }
          });
          const allUsers = responseUsers.data;

        // Filtrar os usuários pelas IDs de interação
        const filteredUsers = allUsers.filter(user => userIDs.has(user.id));
        let contagemMasculino = 0, contagemFeminino = 0, contagemOutros = 0;

        // Contar os gêneros dos usuários filtrados
        filteredUsers.forEach(user => {
          switch (user.genero) {
            case 'M':
              contagemMasculino++;
              break;
            case 'F':
              contagemFeminino++;
              break;
            default:
              contagemOutros++;
          }
        });

        setNumUsersMasc(contagemMasculino);
        setNumUsersFem(contagemFeminino);
        setNumUsersOutros(contagemOutros);

      } catch (error) {
        console.error("Erro ao buscar os usuários: ", error);
      }
    }

    fetchInteracoes();
  }, [selectedMachine]);

  const data = [
    {
      id: "Masculino",
      label: "Masculino",
      value: numUsersMasc,
      color: "hsl(90, 70%, 50%)"
    },
    {
      id: "Feminino",
      label: "Feminino",
      value: numUsersFem,
      color: "hsl(56, 70%, 50%)"
    },
    {
      id: "Outros",
      label: "Outros",
      value: numUsersOutros,
      color: "hsl(26%, 70%, 50%)"
    },
  ];
  
    return (
      <ResponsivePie
        data={data}
        theme={{
          fontSize: "14px",
          fontFamily: "sans-serif"
        }}
        margin={{ top: 20, right: 85, bottom: 50, left: 85 }}
        innerRadius={0.5}
        colors={{ scheme: 'paired' }}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: "color", modifiers: [["darker", 44]] }}
        legends={[
          {
              anchor: 'top-left',
              direction: 'column',
              justify: false,
              translateX: 0,
              translateY: 0,
              itemWidth: 100,
              itemHeight: 20,
              itemsSpacing: 0,
              symbolSize: 20,
              itemDirection: 'left-to-right'
          }
      ]}
      />
    );
  };

export default Pie;