import  React, { useEffect } from 'react';
import useDrivePicker from 'react-google-drive-picker';

const GoogleDrivePicker = () => {
    const [openPicker, authResponse] = useDrivePicker();  

    const handleOpenPicker = () => {
        openPicker({
          clientId: "698424966136-cig8jeemecve3kg4eocigqacrjejbijb.apps.googleusercontent.com",
          developerKey: "AIzaSyAV9AuHWIeAvZQ7OVVtvw4lf6-vXnrHgWE",
          viewId: "DOCS",
          // token: token, // pass oauth token in case you already have one
          showUploadView: true,
          showUploadFolders: true,
          supportDrives: true,
          multiselect: true,
          // customViews: customViewsArray, // custom view
          callbackFunction: (data) => {
            if (data.action === 'cancel') {
              
            }
            console.log(data)
          },
        })
      }

    return(
        <div>
            <button onClick={() => handleOpenPicker()}>Google Drive</button>
        </div>
    );
};

export default GoogleDrivePicker;