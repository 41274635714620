import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children }) => {
  const token = localStorage.getItem('token');

  // Se o usuário estiver autenticado (token presente), redireciona da página de login
  if (token) {
    return <Navigate to="/Painel_de_controle" />;
  }

  // Se não estiver autenticado, permite acesso à rota pública (ex.: Login)
  return children;
};

export default PublicRoute;
