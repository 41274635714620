import Sidebar from "../../../components/Sidebar";
import  Header  from "../../../components/Header/Header";
import "../../EcoTV/EcoVideo/EcoVideo.css"
import Back from '../../../components/img/Voltar.png'
import { Link } from "react-router-dom";
import React from "react";
import ReactPlayer from 'react-player';
import { BiBorderRadius } from "react-icons/bi";
import VideoEco from "../EcoVideo/VideoEco.mp4"
const EcoVideo = ()=>{
    return (

      <div className="div-EcoVideo">
          <Sidebar/>
          
            <div style={{flex:'1'}}>
                <Header/>
                <div className="padding-EcoVideo">
                    <div className="body_EcoVideo">
                        <Link exat to = "/EcoTV" id="BotaoVoltar_EcoVideo" >
                        <img src={Back} alt="Botao-de-voltar" />  
                        </Link>
                        <div className="VideoPlayer_EcoTV">
                            <h1>Propaganda X</h1>
                            <ReactPlayer className="VideoPlayer_EcoTV"
                                url={VideoEco}
                                width="1200px" height="720px" controls={true}
                                
                            />
                        </div>
                        
                        <div></div>   
                  

                    </div>
                </div>
            </div>

        </div>


      
      );
};

export default EcoVideo;