import React, { useState, useEffect } from "react";
import '../CreateTec/modal_tecnicos.css'
import axios from 'axios';
import { Headers } from "node-fetch";

function Modal_tecnicos({ isOpen, onClose, tecnicoToEdit, onUpdateTecnico }) {
  const [editedTecnico, setEditedTecnico] = useState({ ...tecnicoToEdit });
  const [cepError, setCepError] = useState(null);
  const [telefoneerror, telefoneError] = useState(null);
  const [ddderror, setddderror] = useState(null);
  const [errorEmailMessage, setErrorEmailMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [userEcoId, setUserEcoId] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [roleAtual, setRoleAtual] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "CEP") {
      setCepError(null); 
      if (value.length !== 8) {

        setCepError("O CEP deve conter exatamente 8 números.");
      }
    }
    if (name === "telefone") {
      telefoneError(null); 
      if (value.length !== 9) {

        telefoneError("O telefone deve conter exatamente 9 números.");
      }
    }
    if (name === "DDD"){
      setddderror(null);
      if (value.length !==2){
        setddderror("O DDD deve conter exatamente 2 números")
      }
    }
    setEditedTecnico({ ...editedTecnico, [name]: value });
    setErrorMessage('');
    setErrorEmailMessage('');
  };
console.log(typeof editedTecnico.dataNacimento)

console.log(editedTecnico.dataNacimento.split('T')[0])
    ////////// FUNÇÃO PARA AJUSTAR FORMATO PASSADO PELO BACK DE DATA //////////
    function formatDateTime(dateTimeStr) {
      
    
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };
      const date = new Date(dateTimeStr);
      const formattedDateTime = date.toLocaleDateString('pt-BR', options).replace(/\//g, '-') + ' ' ;
      return formattedDateTime;
    }


  useEffect(() => {
    if (tecnicoToEdit) {
      setEditedTecnico({ ...tecnicoToEdit });
      setUserEcoId(tecnicoToEdit.userEcoId);

      axios.get(`https://www.repenseapi.com/api/v1/userEco/${tecnicoToEdit.userEcoId}`, {
        headers: {
          'api': process.env.REACT_APP_API_KEY
        }
      })
      .then(response => {
        const role = response.data.role;
        setRoleAtual(role);
      })
      .catch(error => {
        console.error('Erro ao obter a role do usuário:', error);
      });
    }
  }, [tecnicoToEdit]);


  const handleRoleChange = async (e) => {
    setSelectedRole(e.target.value);

    try {
      const response = await axios.patch(
        `https://www.repenseapi.com/api/v1/userEco/${userEcoId}`,
        { role: e.target.value }, {
          headers: {
            'api': process.env.REACT_APP_API_KEY
          }
        }
      );

      if (response.status === 200) {
        console.log('Role updated successfully.');
        // Additional logic after role update if needed
      } else {
        console.error('Erro ao atualizar o papel do usuário:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao atualizar o papel do usuário:', error);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
  
    console.log('editedTecnicos:', editedTecnico);
  
    if (editedTecnico.id !== undefined && editedTecnico.id !== null) {
      const tecnicoDataToUpdate = {
        nome: editedTecnico.nome,
        genero: editedTecnico.genero,
        dataNacimento: editedTecnico.dataNacimento.split('T')[0],
        CEP: editedTecnico.CEP,
        DDD: editedTecnico.DDD,
        telefone: editedTecnico.telefone,
      };
  
      try {
        const response = await axios.patch(
          `https://www.repenseapi.com/api/v1/tecnicos/${editedTecnico.id}`,
          tecnicoDataToUpdate, {
            headers: {
              'api': process.env.REACT_APP_API_KEY
            }
          }
          
        );
  
        if (response.status === 200) {
          onClose();
          if (typeof onUpdateTecnico === 'function') {
            onUpdateTecnico(editedTecnico);
          }
        } else {
          console.error('Erro ao atualizar a máquina:', response.statusText);
          onClose();
        }
      } catch (error) {
            const errorData = error.response.data;
            const errorResponse = error.response.request.response;
            const intento = JSON.parse(errorResponse);
            console.log('Error14:',error.response);
            console.log('Error1:',intento.message);
            console.log('Error2:',intento.errors[0].message);
            setErrorEmailMessage(intento.message)
            setErrorMessage(intento.errors[0].message)
      }
    } else {
      console.error('ID da máquina não está definido ou é inválido.');
    }
  };
  return (
    <div className={`modal_tec ${isOpen ? 'open' : 'closed'}`}>
      <div className="modal-content_tec">
        <h2 className="h2_content_tec">Editar {roleAtual}</h2>
        <form onSubmit={handleSubmit}>
          <label className="label_modal_tec">
          <p className="p_modal_tec">Nome:</p>
            <input
              className="input_modal_tec"
              type="text"
              name="nome"
              value={editedTecnico.nome}
              onChange={handleInputChange}
            />
          </label>
          <p className="p_modal_tec">Função:</p>
            <select
              className="input_modal_tec"
              name="role"
              value={selectedRole}
              onChange={handleRoleChange}
            >
              <option value="">Selecione uma função</option>
              <option value="admin">Admin</option>
              <option value="tecnico">Tecnico</option>
            </select>
         <label className="label_modal_tec">
          <p className="p_modal">Gênero:</p>
  <select 
    className="input_modal_tec"
    name="genero"
    value={editedTecnico.genero}
    onChange={handleInputChange}
  >
   
    <option value="masculino">Masculino</option>
    <option value="feminino">Feminino</option>
    <option value="outro">Outro</option>
  </select>
</label>
          <label className="label_modal_tec">
            <p className="p_modal">dataNacimento:</p>
            <input 
              className="input_modal_tec"
              type="text"
              name="dataNacimento"
              value={editedTecnico.dataNacimento ? editedTecnico.dataNacimento.split('T')[0] : ''}
              onChange={handleInputChange}
            />
          </label>
          <label className="label_modal_tec">
              <p className="p_modal">CEP:</p>
              <input 
                className="input_modal_tec"
                type="text"
                name="CEP"
                value={editedTecnico.CEP}
                onChange={handleInputChange}
              />
            </label>
            {/* Exibir mensagem de erro do CEP se houver um erro */}
            {cepError && <p className="error-message">{cepError}</p>}
          <label className="label_modal_tec">
            <p className="p_modal">DDD:</p>
            <input 
              className="input_modal_tec"
              type="text"
              name="DDD"
              value={editedTecnico.DDD}
              onChange={handleInputChange}
            />
          </label>
          { ddderror && <p className="error-message">{ddderror}</p>}
          <label className="label_modal_tec">
            <p className="p_modal">Telefone:</p>
            <input 
              className="input_modal_tec"
              type="text"
              name="telefone"
              value={editedTecnico.telefone}
              onChange={handleInputChange}
            />
          </label>
          {telefoneerror && <p className="error-message">{telefoneerror}</p>}
          {errorMessage && <div style={{ color: 'red' }} className="error">{errorMessage } </div>}
          {errorEmailMessage && <p className="error-message">{errorEmailMessage}</p>} 
          <button className="button_modal_save_tec" disabled={errorMessage !== ''} onClick={handleSubmit}>Salvar</button>
          <button className="button_modal_cancel_tec" onClick={onClose}>Cancelar</button>
        </form>
      </div>
    </div>
  );
}

export default Modal_tecnicos;