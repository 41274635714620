import { ResponsivePie } from "@nivo/pie";
import axios from "axios";
import { useState, useEffect } from "react";
import { differenceInYears } from "date-fns";

const Pie = ({ selectedMachine }) => {
  const [users, setUsers] = useState([]);
  const [ageData, setAgeData] = useState([]);

  useEffect(() => {
    async function fetchInteractions() {
      try {
        let totenId = null;

        if (selectedMachine && selectedMachine !== "Geral") {
          const responseTotens = await axios.get("https://www.repenseapi.com/api/v1/machine", {
            headers: {
              'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key real
            }
          });
          const totenData = responseTotens.data.find(toten => toten.nome === selectedMachine);
          if (totenData) {
            totenId = totenData.id; 

          }
        }

        const responseInteracoes = await axios.get("https://www.repenseapi.com/api/v1/interacoes", {
          headers: {
            'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key real
          }
        });
        let interacoes = responseInteracoes.data;


        if (totenId) {
          interacoes = interacoes.filter(interacao => interacao.maquinaId === totenId);
        }

        const userIDs = new Set(interacoes.map(interacao => interacao.userId));
        const responseUsers = await axios.get("https://www.repenseapi.com/api/v1/users", {
          headers: {
            'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key real
          }
        });
        const allUsers = responseUsers.data;
        const filteredUsers = allUsers.filter(user => userIDs.has(user.id));


        const ageGroups = calculateAgeGroups(filteredUsers);
        setAgeData(ageGroups);
      

      } catch (error) {
        console.error("Erro ao buscar as interações: ", error);
      }
    }

    fetchInteractions();
  }, [selectedMachine]);

  const calculateAgeGroups = (users) => {
    const currentDate = new Date();
    const ageGroups = {
      "18-25": 0,
      "25-35": 0,
      "35-45": 0,
      "45-60": 0,
      "60+": 0
    };

    users.forEach((user) => {
      const birthDate = new Date(user.data_nascimento);
     
      const age = differenceInYears(currentDate, birthDate);

      if (age >= 18 && age <= 25) {
        ageGroups["18-25"]++;
      } else if (age > 1 && age <= 35) {
        ageGroups["25-35"]++;
      } else if (age > 35 && age <= 45) {
        ageGroups["35-45"]++;
      } else if (age > 45 && age <= 60) {
        ageGroups["45-60"]++;
      } else if (age > 60) {
        ageGroups["60+"]++;
      }
    });

    return Object.entries(ageGroups).map(([id, value]) => ({
      id,
      value,
    }));
  };

  return (
    <ResponsivePie
      theme={{
        fontSize: "14px",
        fontFamily: "sans-serif",
      }}
      data={ageData}
      margin={{ top: 20, right: 80, bottom: 50, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.3]] }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: "color", modifiers: [["darker", 14]] }}
      legends={[
        {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 25,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 10,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemTextColor: '#000'
                    }
                }
            ]
        }
    ]}
    />
  );
};

export default Pie;
