import { Link } from "react-router-dom";
import React, { useState,useEffect  } from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar";
import "./modalEco.css";
import { jwtDecode } from 'jwt-decode';
import GoogleDrivePicker from "../../components/GoogleDrivePicker";

function MachineTableComponent() {
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserRole(decodedToken.role); // 'role' é o campo do token que indica o papel do usuário
      } catch (error) {
        console.error('Erro ao decodificar o token: ', error);
      }
    }
  }, []);

  return ( 
    <div className="div-maquinas">
      <Sidebar />
      <div style={{ flex: "1" }} >
        <Header />
        <div className="padding-Maquinas">
          <div className="nav_Maquinas">
            <p className="titulo_Maquinas">EcoTv</p>
            <div className="navEndereco_Maquinas">
              <Link exact to='/Maquinas' className="home-redirect-Maquinas">
                <p className="navHome_Maquinas" >Home</p>
              </Link>
              <p className="navAtual_Maquinas"> &gt; </p>
              <p className="navAtual_Maquinas">EcoTv</p> 
            </div>
          </div>
          <div className= "search-submit-container-Maquinas">
            <GoogleDrivePicker/>
            <a href="https://drive.google.com/drive/folders/1vzZeFk_QyP9tv0Ex-982-pKJGbOmk8Vt?usp=drive_link" target="_blank">Google Drivee</a>
          </div>
 

        </div>
   
      </div>
    </div>
  );
}

export default MachineTableComponent;