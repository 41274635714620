import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar";
import "../Maquinas/Maquinas.css";
import axios from 'axios';
import Modal_maquinas from "./modal_maquinas";
import { jwtDecode } from 'jwt-decode';

function MachineTableComponent() {
  const [machineToEdit, setMachineToEdit] = useState(null);
  const [totens, setTotens] = useState([]);
  const [searchId, setSearchId] = useState('');
  const [filteredToten, setFilteredToten] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isBackgroundOpaque, setIsBackgroundOpaque] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [showInactiveMachines, setShowInactiveMachines] = useState(false);
  // Alterado para usar um objeto em vez de um array
  const [showMenu, setShowMenu] = useState({});

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserRole(decodedToken.role);
      } catch (error) {
        console.error('Erro ao decodificar o token: ', error);
      }
    }
  }, []);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (!e.target.closest(".dropdown-container-Tecnicos")) {
        // Fecha todos os dropdowns
        setShowMenu({});
      }
    };

    document.addEventListener("click", handleDocumentClick);
    return () => document.removeEventListener("click", handleDocumentClick);
  }, []);

  useEffect(() => {
    async function fetchTotens() {
      try {
        const response = await axios.get('https://www.repenseapi.com/api/v1/machine', {
          headers: {
            'api': process.env.REACT_APP_API_KEY  // Substitua SUA_API_KEY_AQUI pela sua API Key real
          }
        });
        setTotens(response.data);
        // Não é necessário inicializar showMenu aqui, pois o estado é agora um objeto vazio por padrão
      } catch (error) {
        console.error('Erro ao obter dados de totens:', error);
      }
    }
    fetchTotens();
  }, []);

  useEffect(() => {
    const filtered = totens.filter((toten) => {
      const searchValue = searchId.toLowerCase();
      return (toten.id.toString().includes(searchValue) || 
              toten.nome.toLowerCase().includes(searchValue) || 
              toten.CEP.toString().includes(searchValue)) && 
              (toten.status === true || showInactiveMachines);
    });
    console.log('fil',totens)
    setFilteredToten(filtered);
  }, [totens, searchId, showInactiveMachines]);

  const handleEditClick = (machineId) => {
    const machine = totens.find(toten => toten.id === machineId);
    setMachineToEdit(machine);
    setIsOpen(true);
  };

  const handleDeleteMaquina = async (id) => {
    try {
      await axios.delete(`https://www.repenseapi.com/api/v1/machine/${id}`, {
        headers: {
          'api': process.env.REACT_APP_API_KEY  // Substitua SUA_API_KEY_AQUI pela sua API Key real
        }
      });
      const updatedTotens = totens.filter(toten => toten.id !== id);
      setTotens(updatedTotens);
    } catch (error) {
      console.error('Erro ao excluir Máquina:', error);
    }
  };

  const handleRegisterClick = () => {
    setIsOpen(true);
    setIsBackgroundOpaque(false);
  };

  const handleModalClose = () => {
    setIsOpen(false);
    setIsBackgroundOpaque(true);
  };

  const handleUpdateMachine = (updatedMachine) => {
    const updatedTotens = totens.map(toten =>
      toten.id === updatedMachine.id ? updatedMachine : toten
    );
    setTotens(updatedTotens);
  };

  const toggleMenu = (machineId) => {
    // Alterna a visibilidade do dropdown específico, usando o ID como chave
    setShowMenu(prevShowMenu => ({
      ...prevShowMenu,
      [machineId]: !prevShowMenu[machineId]
    }));
  };
  return ( 
    <div className="div-maquinas">
      <Sidebar />
      <div style={{ flex: "1" }} >
        <Header />
        <div className="padding-Maquinas">
          <div className="nav_Maquinas">
            <p className="titulo_Maquinas">Lista de máquinas</p>
            <div className="navEndereco_Maquinas">
              <Link exact to='/Painel_de_controle' className="home-redirect-Maquinas">
                <p className="navHome_Maquinas" >Home</p>
              </Link>
              <p className="navAtual_Maquinas"> &gt; </p>
              <p className="navAtual_Maquinas">Maquinas</p> 
            </div>
          </div>
          <div className= "search-submit-container-Maquinas">
            <input className={`search-container-Maquinas ${isOpen ? 'opaque-table' : ''}`}
              type="text" 
              placeholder="Pesquisar..." 
              value={searchId}
              onChange={(e) => setSearchId(e.target.value)}
            />
            {userRole === 'admin' && (
            <Link exact to="/CadastroMaquina">
              <button className={`botão-style-Maquinas ${isOpen ? 'opaque-table' : ''}`} >Cadastrar</button>
            </Link>
            )}
          </div>
          <div className="checkbox-container">
              <input
                className="checkBox_Maquinas"
                type="checkbox"
                id="show-inactive"
                checked={showInactiveMachines}
                onChange={(e) => setShowInactiveMachines(e.target.checked)}
              />
              <label htmlFor="show-inactive">Mostrar Máquinas Inativas</label>
          </div>
          
          <div className="div-tabela">
            <table className={`table-style-Machine ${isOpen ? 'opaque-table' : ''}`}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Serial</th>
                  <th>CEP</th>
                  <th>Status</th>
                  <th>Descrição</th>
                  {userRole === 'admin' && (
                  <th>Ações</th>)}
                </tr>
              </thead>
              <tbody className={ `table-body-Maquinas ${isBackgroundOpaque ? '' : 'transparent-background'}`}>
                {(searchId === '' || !searchId)
                  ? filteredToten.map((toten) => (
                    <tr key={toten.id} className="movie-container">
                      <td className="movie-img">{toten.id}</td>
                      <td>{toten.nome}</td>
                      <td>{toten.serial}</td>
                      <td>{toten.CEP}</td>
                      <td>{toten.status ? 'Ativa' : 'Inativa'}</td>
                      <td>{toten.descricao}</td>
                      {userRole === 'admin' && (
                      <td className="EngrenagemTec">
                        <div className="dropdown-container-Tecnicos">
                          <button className="dropbtn" onClick={() => toggleMenu(toten.id)}>
                            
                          </button>
                          <div className={`dropdown-menu-EcoTV ${showMenu[totens.findIndex(t => t.id === toten.id)] ? 'show' : ''}`}>
                          <button className="dropdown-item" onClick={() => handleEditClick(toten.id)}>Editar</button>
                          <button className="dropdown-item delete-button" onClick={() => handleDeleteMaquina(toten.id)}>Deletar</button>
                        </div>
                        </div>
                      </td>
                    )}
                  </tr>
                  ))
                  : filteredToten.map((toten) => (
                    <tr key={toten.id} className="movie-container">
                    <td className="movie-img">{toten.id}</td>
                    <td className="nome-Maquinas">{toten.nome}</td>
                    <td>{toten.serial}</td>
                    <td>{toten.CEP}</td>

                
                

                    <td>{toten.status ? 'Ativa' : 'Inativa'}</td>
                    <td>{toten.descricao}</td>
                    {userRole === 'admin' && (
                    <td className="EngrenagemTec">

                      <div className="dropdown-container-Tecnicos">
                        <button className="dropbtn" onClick={() => toggleMenu(toten.id)}>
                          
                        </button>
                        <div className={`dropdown-menu-EcoTV ${showMenu[toten.id] ? 'show' : ''}`}>
                        <button className="dropdown-item" onClick={handleRegisterClick}>Editar</button>
                        
                          <button className="dropdown-item" onClick={() => handleDeleteMaquina(toten.id)}>Deletar</button>
                        </div>

      

                      </div>
                    </td>
                    )}
                  </tr>
                ))}
                
              
              </tbody>
            </table>

          </div>
 

        </div>
   
      </div>
      {isOpen && (
        <Modal_maquinas
          isOpen={isOpen}
          onClose={handleModalClose}
          machineToEdit={machineToEdit}
          onUpdateMachine={handleUpdateMachine}
        />
      )}
    </div>
  );
}
export default MachineTableComponent;
