import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header/Header';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import axios from 'axios';
import '../Localização/Localização.css';
import maquinaIcon from '../../components/img/maquina2.png';
import { Link } from "react-router-dom";

const Localização = () => {
  const [machines, setMachines] = useState([]);
  const [mapCenter, setMapCenter] = useState({ lat: -32.06799095848746, lng: -52.15341137042274 });
  const [markerPositions, setMarkerPositions] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [zoom, setZoom] = useState(15);


  

  const markerStyle = {
    fontSize: '44px', // Tamanho da fonte, você pode ajustar conforme necessário
    fontWeight: 'bold', // Peso da fonte, você pode ajustar conforme necessário
    color: 'red', // Cor do texto do marcador, você pode ajustar conforme necessário
  };
  const mapContainerStyle = {
    width: '100%',
    height: '80vh',
  };

  const handleMachineSelect = (event) => {
    setZoom(18);
    const selectedMachineId = event.target.value;
    setSelectedMachine(selectedMachineId);
    if (selectedMachineId) {
      const selectedMachineData = machines.find(machine => machine.id === parseInt(selectedMachineId));
      if (selectedMachineData && selectedMachineData.Latitude && selectedMachineData.Longitude) {
        setMapCenter({
          lat: parseFloat(selectedMachineData.Latitude),
          lng: parseFloat(selectedMachineData.Longitude),
        });
      } else {
        console.error('Latitude e/ou Longitude não disponíveis para esta máquina.');
      }
    }
  };
  useEffect(() => {
  
    const reloadValue = localStorage.getItem('reload');
    if (reloadValue === '1') {
      localStorage.removeItem('reload'); 
      window.location.reload();
    }
  }, []);
  useEffect(() => {
    axios.get('https://www.repenseapi.com/api/v1/machine',{
      headers:{
        'api' : process.env.REACT_APP_API_KEY 
      }
    })
    
      .then(response => {
        setMachines(response.data);
        // Filtra máquinas com Latitude e Longitude válidos e os armazena em markerPositions
        const validPositions = response.data.filter(machine => machine.Latitude && machine.Longitude)
                                             .map(machine => ({
                                               lat: parseFloat(machine.Latitude),
                                               lng: parseFloat(machine.Longitude),
                                               id: machine.id
                                             }));
        setMarkerPositions(validPositions);
      })
      .catch(error => {
        console.error('Erro ao obter dados das máquinas:', error);
      });
  }, []);


  

  const [activeInfoWindow, setActiveInfoWindow] = useState(null);
  const handleMarkerClick = (machineId) => {
    if (activeInfoWindow !== machineId) {
      setActiveInfoWindow(machineId);
    }
  };




  return (
    <div className="div-localização">
      <Sidebar />
      <div style={{ flex: '1' }}>
        <Header />
        <div className="padding-localização">
          <div className="nav_Localizacao">
            <p className="titulo_Localizacao">Localização</p>
            <div className="navEndereco_Localizacao">
            <Link className="navHome_Localizacao" exact to="/Painel_de_controle" title="EcoTV" >Home</Link>
              <p className="navAtual_Localizacao">&gt;</p>
              
              
              <p className="navAtual_Localizacao">Localização</p>
            </div>
          </div>
          <div className="body_Localizacao">
            <div className="mapCard_Localizacao">
              <select className='selectMachine' value={selectedMachine} onChange={handleMachineSelect}>
                <option value={null}>Selecione uma máquina</option>
                {machines.map((machine) => (
                  <option key={machine.id} value={machine.id}>
                    {machine.nome}
                  </option>
                ))}
              </select>

                <LoadScript googleMapsApiKey="AIzaSyCn674e42nuGTkY8eJ-5spNmBErvtE41V8" loading="async">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={mapCenter}
                  zoom={zoom}
                >
                  
                  {markerPositions.map((position, index) => (
                    
                    <Marker
                      key={position.id}
                      position={{ lat: position.lat, lng: position.lng }}
                      onClick={() => handleMarkerClick(machines[index].id)}
                      icon={{
                        url: maquinaIcon,
                        scaledSize: new window.google.maps.Size(90, 62),
                        labelOrigin: new window.google.maps.Point(42, -10) // Ajuste a posição do label
                      }} 
                      label={{
                        text: machines[index].nome,
                        fontFamily: 'Roboto Condensed, sans-serif',
                        fontWeight: '800', // Torna o texto mais ousado
                        fontSize: '18px', // Aumenta o tamanho da fonte
                        color: 'rgb(0, 162, 255)'
                      }}
                    >
                      {activeInfoWindow === machines[index].id && (
                        <InfoWindow
                          position={position}
                          onCloseClick={() => setActiveInfoWindow(null)}
                        >
                         {machines[index].descricao ? (
                            <div>
                              <h2>{machines[index].descricao}</h2>
                            </div>
                          ) : null}
                        </InfoWindow>
                      )}
                    </Marker>
                  ))}
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Localização;
