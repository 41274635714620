// src/AppWrapper.js
import React, { useEffect } from 'react';
import axios from 'axios';

const AppWrapper = ({ children }) => {
  useEffect(() => {
    const verificarChamadas = async () => {
      try {
        const { data } = await axios.get('https://www.repenseapi.com/api/v1/chamadas', {
          headers: {
            'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key real
          }
        });
        const temChamadaAtiva = data.some(chamada => 
          chamada.tecnico && 
          (chamada.status === 'Aguardando técnico' || chamada.status === 'Em andamento')
        );

        localStorage.setItem('chamada', temChamadaAtiva ? 'true' : 'false');
      } catch (error) {
        console.error('Erro ao obter dados das chamadas:', error);
        localStorage.setItem('chamada', 'false');
      }
    };

    verificarChamadas();
    const intervalId = setInterval(verificarChamadas, 6000); // Verifica a cada 1 minuto

    return () => clearInterval(intervalId);
  }, []);

  return <>{children}</>;
};

export default AppWrapper;
