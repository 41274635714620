import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header/Header';
import { Link } from 'react-router-dom';
import '../Chamadas/Chamadas.css';
import Modal from 'react-modal';
import { jwtDecode } from 'jwt-decode';


const Chamadas = () => {
  //////////   ALGUMAS CONSTANTES //////////
  const [chamadas, setChamadas] = useState([]);
  const [tecnicos, setTecnicos] = useState([]); // State to store technicians
  const [showFinalizados, setShowFinalizados] = useState(false);
  //////////      //////         //////////
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTecnico, setSelectedTecnico] = useState('');
  const [currentChamadaId, setCurrentChamadaId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [userRole, setUserRole] = useState(null);
  
  Modal.setAppElement('#root');

  //Validar Role
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserRole(decodedToken.role); // 'role' é o campo do token que indica o papel do usuário
      } catch (error) {
        console.error('Erro ao decodificar o token: ', error);
      }
    }
  }, []);
  
  
  const openModal = (chamada) => {
    setCurrentChamadaId(chamada.id);
    console.log(chamada.id);
    setSelectedTecnico(chamada.tecnicoId);
    setIsModalOpen(true);
  };

  const updateMachineStatus = (maquinaId) => {
    axios.patch(`https://www.repenseapi.com/api/v1/machine/${maquinaId}`, {
      status: false // Atualiza o status para false
    }, {
      headers: {
        'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key real
      }
    })
    .then(response => {
      
      // Aqui você pode adicionar qualquer lógica adicional após a atualização bem-sucedida
    })
    .catch(error => {
      console.error('Erro ao atualizar o status da máquina:', error);
    });
  };
  


  const handleSalvar = () => {
    // Realiza o patch para atualizar a chamada com o técnico selecionado
    axios.patch(`https://www.repenseapi.com/api/v1/chamadas/${currentChamadaId}`, {
      tecnicoId: selectedTecnico,
      status: "Aguardando técnico"
    }, {
      headers: {
        'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key real
      }
    })
    .then(response => {
      // Encontra o índice da chamada atualizada
      const chamadaIndex = chamadas.findIndex(chamada => chamada.id === currentChamadaId);
      // Atualiza o objeto da chamada com os novos dados
      let updatedChamadas = [...chamadas];
      updatedChamadas[chamadaIndex] = {
        ...updatedChamadas[chamadaIndex],
        tecnico: tecnicos.find(tecnico => tecnico.id === Number(selectedTecnico)),
        tecnicoId: selectedTecnico,
        status: "Aguardando técnico"
      };
      // Atualiza o estado com a nova lista de chamadas
      setChamadas(updatedChamadas);
      // Fecha o modal
      setIsModalOpen(false);
    })
    .catch(error => {
      console.error('Erro ao atualizar a chamada:', error);
    });
  };


  ////////// FUNÇÃO PARA AJUSTAR FORMATO PASSADO PELO BACK DE DATA //////////
  function formatDateTime(dateTimeStr) {
    if (dateTimeStr === null) {
      return ""; // Retorna uma string vazia quando a entrada é null
    }
  
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateTimeStr);
    const formattedDateTime = date.toLocaleDateString('pt-BR', options).replace(/\//g, '-') + ' ' ;
    return formattedDateTime;
  }
////////// FUNÇÃO PARA AJUSTAR FORMATO PASSADO PELO BACK //////////


//////////  REALIZAR A BUSCA NAS CHAMADAS E NOS TECNICOS //////////
  
  useEffect(() => {
    // Fetch chamadas
    axios.get('https://www.repenseapi.com/api/v1/chamadas', {
      headers: {
        'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key real
      }
    })
      .then(response => {
        setChamadas(response.data);
        response.data.forEach(chamada => {
          if (chamada.status === "Aguardando Atribuição") {
            updateMachineStatus(chamada.maquinaId);
          }
        });
      })
      .catch(error => {
        console.error('Erro ao obter dados das chamadas:', error);
      });

    // Fetch tecnicos
    axios.get('https://www.repenseapi.com/api/v1/tecnicos', {
      headers: {
        'api': process.env.REACT_APP_API_KEY// Substitua SUA_API_KEY_AQUI pela sua API Key real
      }
    })
      .then(response => {
        setTecnicos(response.data);
      })
      .catch(error => {
        console.error('Erro ao obter dados dos técnicos:', error);
      });
      
  }, []);
  
//////////  REALIZAR A BUSCA NAS CHAMADAS //////////
  
  const filteredChamadas = chamadas.filter((chamada) =>
    chamada.maquina.nome.toLowerCase().includes(searchTerm) ||
    chamada.tipo.toLowerCase().includes(searchTerm) ||
    (chamada.tecnico && chamada.tecnico.nome.toLowerCase().includes(searchTerm))
  );

  



  return (
    <div className="div-chamadas">
      <Sidebar />
      <div style={{ flex: '1' }}>
        <Header />
        {userRole === 'tecnico' && (<h1 className='AvisoTecnicosHH'>Você não pode acessar essa página!!!</h1>)}
        {userRole === 'admin' && ( 
        <div className="padding-chamadas">
          <div className="nav_chamadas">
            <p className="titulo_chamadas">Lista de chamadas</p>
            <div className="navEndereco_chamadas">
              <Link exact to='/Painel_de_controle' className="home-redirect-chamadas"><p className="navHome_chamadas" >Home</p></Link>
              <p className="navAtual_chamadas" > &gt; </p>
              <p className="navAtual_chamadas">Chamadas</p>
            </div>
          </div>
          <div className="topCardChamadas">
            <input
              className='PesqChamadas'
                type="text" 
                placeholder="Pesquisar..." 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
            />
          </div>
          <div className='Checkbox_Chamadas_div'>
              <input
                type="checkbox"
                checked={showFinalizados}
                onChange={() => setShowFinalizados(!showFinalizados)}
              />
              <p>Mostrar chamadas finalizadas</p>
          </div>
          <div className="Bodychamadas">
          <Modal
            closeTimeoutMS={350}
            className="ModalContent" // Esta é a classe para o conteúdo do modal
            overlayClassName="ModalOverlay"
            isOpen={isModalOpen}
            contentLabel="Escolher Técnico"
          >
            <h2>Designar Responsável</h2>
            <select
              className='SelectResponsavel'
              value={selectedTecnico || ""}
              onChange={(e) => setSelectedTecnico(e.target.value)}
            >
              <option value="">Escolha um responsável</option>
              {tecnicos.map((tecnico) => (
                <option key={tecnico.id} value={tecnico.id}>
                  {tecnico.nome}
                </option>
              ))}
            </select>
            <button onClick={handleSalvar}>Salvar</button>
            <button onClick={() => setIsModalOpen(false)}>Fechar</button>

          </Modal>
                
            <table className="TablechamadasStyle">
              <thead className="theadchamadas">
                <tr>
                  <th>Máquina</th>
                  <th>Alarme</th>
                  <th>Abertura</th>
                  <th>Atendimento</th>
                  <th>Encerramento</th>
                  <th>Responsável</th>
                  <th>Designar Responsável</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody className="tbodyChamadas">
                {filteredChamadas
                  .filter((chamada) =>
                    showFinalizados ? true : chamada.status !== "Finalizado"
                  )
                  .map((chamada) => (
                  
                  <tr key={chamada.id}>
                    <td className="nome-Maquinas-chamadas">{chamada.maquina.nome}</td>
                    <td>{chamada.tipo}</td>
                    <td>{formatDateTime(chamada.createdAt)}</td>
                    <td>{formatDateTime(chamada.atendimento)}</td>
                    <td>{formatDateTime(chamada.encerramento)}</td>
                    <td>{chamada.tecnico ? chamada.tecnico.nome : 'Técnico não atribuido'}</td>
                    <td>
                      <button className='btnAbrirModal_Chamadas' onClick={() => openModal(chamada)}>Editar responsável</button>
                    </td>
                    <td className={chamada.status === "Em andamento" ? "status-andamento" : 
                                  chamada.status === "Finalizado" ? "status-finalizado" :
                                  chamada.status === "Aguardando técnico" || chamada.status === "Aguardando responsavel" ? "status-aguardando-tecnico" : ""}>
                      {chamada.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        )} 
      </div>
    </div>
  );
};

export default Chamadas;