import React, { useState, useEffect } from "react";
import '../CreateTec/modal_tecnicos.css'
import axios from 'axios';
import { colors } from "@mui/material";

function Modal_info_tec({ isOpenn, onClosee, tecnicoToEditt }) {
  const [loadedTecnico, setLoadedTecnico] = useState(null);

  // Ao abrir o modal, fazer uma solicitação GET para obter os detalhes do técnico
  useEffect(() => {
    if (isOpenn && tecnicoToEditt) {
      async function fetchTecnicoDetails() {
        try {
          const response = await axios.get(
            `https://www.repenseapi.com/api/v1/tecnicos/${tecnicoToEditt.id}`,{
              headers:{
                'api': process.env.REACT_APP_API_KEY
              }
            }
          );
          setLoadedTecnico(response.data);
        } catch (error) {
          console.error('Erro ao carregar detalhes do técnico:', error);
        }
      }
      fetchTecnicoDetails();
    }
  }, [isOpenn, tecnicoToEditt]);

  const handleInputChange = (e) => {
    
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isOpenn && tecnicoToEditt) {
      async function fetchTecnicoDetails() {
        try {
          const response = await axios.get(
            `https://www.repenseapi.com/api/v1/tecnicos/${tecnicoToEditt.id}`,{
              headers:{
                'api': process.env.REACT_APP_API_KEY
              }
            }
          );
          setLoadedTecnico(response.data);
        } catch (error) {
          console.error('Erro ao carregar detalhes do técnico após a atualização:', error);
        }
      }
      fetchTecnicoDetails();
    }
  };

  return (
    <div className={`modal_tec ${isOpenn ? 'open' : 'closed'}`}>
      <div className="modal-content_tec">
        <h2 className="h2_content_tecc">Detalhes do Técnico</h2>
        {loadedTecnico ? (
          <div className="div_info_tec">
            <p className="p_modal_tecc">Nome:
            <span className="number_get_tec">{loadedTecnico.nome}</span></p>
            <p className="p_modal_tecc">Gênero:
             <span className="number_get_tec">{loadedTecnico.genero}</span></p>
            <p className="p_modal_tecc">Data de Nascimento:
             <span className="number_get_tec">{loadedTecnico.dataNacimento}</span></p>
            <p className="p_modal_tecc">CEP:
             <span className="number_get_tec">{loadedTecnico.CEP}</span></p>
            <p className="p_modal_tecc">DDD:
            <span className="number_get_tec"> {loadedTecnico.DDD }</span></p>
            <p className="p_modal_tecc">
            Telefone:
            <span className="number_get_tec">{loadedTecnico.telefone}</span>
            </p>

            <button className="button_modal_cancel_tecc" onClick={onClosee}>Fechar</button>
          </div>
        ) : (
          <p>Carregando detalhes do técnico...</p>
        )}
      </div>
    </div>
  );
}

export default Modal_info_tec;
