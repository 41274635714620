import React, { useState, useEffect } from "react";
import { ResponsivePie } from "@nivo/pie";
import axios from "axios";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";





  const Pie = () => {
    const [maquinas, setMaquinas] = useState([]);
    const [numeroMaquinas, setNumeroMaquinas] = useState(0);
    const [numAtivas, setNumeroMaquinasAtivas] = useState(0);
    const [numDesativadas, setNumeroMaquinasDesativadas] = useState(0);  
    const [alarmes, setAlarmes] = useState([]);
    const [numeroAlarmes, setNumeroAlarmes] = useState(0);
    const [chamadas, setChamadas] = useState([]);
    const [numeroChamadas, setNumeroChamadas] = useState(0);
    const [numChamadasAtivas, setNumeroChamadasAtivas] = useState(0); 

    const [numChamadasAtivass, setNumeroChamadasAtivass] = useState(0); 
    const [chamadass, setChamadass] = useState([]);
    const [numeroChamadass, setNumeroChamadass] = useState(0);
    const [numAtivass, setNumeroMaquinasAtivass] = useState(0); 
    const [numDesativadass, setNumeroMaquinasDesativadass] = useState(0); 

    const [numAlarmesAtivos, setNumeroAlarmesAtivos] = useState(0); 

    useEffect(() => {
      // Função assíncrona para buscar os dados das maquinas
      async function fetchMaquinas() {
        try {
          const response = await axios.get(
            "https://www.repenseapi.com/api/v1/machine"
            , {
              headers: {
                'api': process.env.REACT_APP_API_KEY// Substitua SUA_API_KEY_AQUI pela sua API Key real
              }
            });
          // Atualiza o estado com os dados recebidos
          setMaquinas(response.data);
  
          // Atualiza o estado com o número de técnicos
  
          const numAtivas = response.data.filter(maquina => maquina.status === true).length;
          setNumeroMaquinasAtivas(numAtivas);
  
          const numDesativadas = response.data.length - numAtivas;
          setNumeroMaquinasDesativadas(numDesativadas);
  
        } catch (error) {
          console.error("Erro ao buscar as máquinas:", error);
        }
      }
  
      // Chama a função de busca quando o componente é montado
      fetchMaquinas();
    }, []);



    useEffect(() => {
      // Função assíncrona para buscar os dados das máquinas e alarmes
      async function fetchData() {
        try {
          const responses = await axios.get(
            "https://www.repenseapi.com/api/v1/chamadas"
            , {
              headers: {
                'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key real
              }
            });
          // Atualiza o estado com os dados recebidos
          setChamadass(responses.data);
  
          // Atualiza o estado com o número de técnicos
  
          setNumeroChamadass(responses.data.length);
  
          const numChamadasAtivass = responses.data.filter(chamadas => chamadas.status !== 'Finalizado').length;
       
          setNumeroChamadasAtivass(numChamadasAtivass);

          const responseses = await axios.get(
            "https://www.repenseapi.com/api/v1/machine"
            , {
              headers: {
                'api': process.env.REACT_APP_API_KEY// Substitua SUA_API_KEY_AQUI pela sua API Key real
              }
            });
          const numAtivass = responseses.data.filter(maquina => maquina.status === true).length;
        setNumeroMaquinasAtivas(numAtivass);

        // const numDesativadass = responseses.data.length - numAtivas;











          // Faz as duas solicitações ao mesmo tempo usando Promise.all
          const [maquinasResponse, chamadasResponse] = await Promise.all([
            axios.get("https://www.repenseapi.com/api/v1/machine", {
              headers: {
                'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key real
              }
            }),
            axios.get("https://www.repenseapi.com/api/v1/chamadas", {
              headers: {
                'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key real
              }
            }),
          ]);

          // Atualiza o estado com os dados recebidos das máquinas e alarmes
          // setMaquinas(maquinasResponse.data);
          // setNumeroMaquinas(maquinasResponse.data.length);
          // const numAtivas = maquinasResponse.data.filter(maquina => maquina.status === 'activa').length;
          // setNumeroMaquinasAtivas(numAtivas);

          // const numDesativadas = response.data.length - numAtivas;
          // setNumeroMaquinasDesativadas(numDesativadas);

          
          
          setChamadas(chamadasResponse.data);
          setNumeroChamadas(chamadasResponse.data.length);
          const numChamadasAtivas = chamadasResponse.data.filter(chamadas => chamadas.status !== 'Finalizado').length;
          setNumeroChamadasAtivas(numChamadasAtivas);

        } catch (error) {
          console.error("Erro ao buscar os dados:", error);
        }
      }

      // Chama a função de busca quando o componente é montado
      fetchData();
    }, []);
    
    const data = [
      {
        id: "Máquinas ativas",
        label: "Máquinas ativas",
        value: numAtivas,
        color: "rgb(70, 230, 109)"
  
      },
      {
        id: "Chamadas ativas",
        label: "Chamadas ativas",
        value: numChamadasAtivass,
        color:"rgb(52, 179, 83)"
      },
      {
        id: "Máquinas Inativas",
        label: "Máquinas Inativas",
        value: numDesativadas,
        color: "#ffffff"
      },
    ];
    return (
      <ResponsivePie
        data={data}
        margin={{ top: 30, right: 80, bottom: 55, left: 5 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
        legends={[
          {
              anchor: 'top-right',
              direction: 'column',
              justify: false,
              translateX: 20,
              translateY: -18,
              itemWidth: 92,
              itemHeight: 43,
              itemsSpacing: 0,
              symbolSize: 20,
              itemDirection: 'left-to-right'
          }
      ]}
      />
    );
  };

export default Pie;