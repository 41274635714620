import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';




const ProtectedRoute = ({ children }) => {
  // useEffect(() => {
  //   // Fetch chamadas
  //   axios.get(`http://localhost:3000/api/v1/userEco/${userSub}`)
  //     .then(response => {
  //       const userId = response.data;
  //       // setChamadas(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Erro ao obter dados das chamadas:', error);
  //     });    
  // }, []);




  const token = localStorage.getItem('token');
  
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const userRole = decodedToken.role;
      const userSub = decodedToken.sub;
    
      // console.log('id do usuário:', userId);
      /////////////
      useEffect(() => {
        // Fetch chamadas
        axios.get(`https://www.repenseapi.com/api/v1/userEco/${userSub}`, {
          headers: {
            'api': process.env.REACT_APP_API_KEY  // Substitua SUA_API_KEY_AQUI pela sua API Key real
          }
        })
          .then(response => {
            const userId = response.data;
          
            // setChamadas(response.data);
          })
          .catch(error => {
            console.error('Erro ao obter dados das chamadas:', error);
          });    
      }, []);
      /////////




      if (userRole === 'admin' || userRole === 'tecnico') {
        return children;
      } else {
        return <Navigate to="/" />;
      }
    } catch (error) {
      console.error('Erro ao decodificar o token: ', error);
      return <Navigate to="/" />;
    }
  } else {
    return <Navigate to="/" />;
  }
};
// export const userId = userSub;


export default ProtectedRoute;
