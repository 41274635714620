import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './token.css'
function Recebimento() {
  const location = useLocation();
  const navigate = useNavigate(); // Adiciona o hook useNavigate
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (novaSenha === confirmarSenha) {
      try {
        // Enviar o token e a nova senha para o backend
        const response = await fetch('https://www.repenseapi.com/api/v1/auth/change-password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api': process.env.REACT_APP_API_KEY 
            
          },
          body: JSON.stringify({ token, newPassword: novaSenha }),
        });

        if (response.ok) {
          alert('Senha redefinida com sucesso!');
          navigate('/');
        } else {
          alert('Ocorreu um erro ao redefinir a senha.');
        }
      } catch (error) {
        console.error('Erro ao enviar solicitação:', error);
        alert('Ocorreu um erro ao redefinir a senha.');
      }
    } else {
      alert('As senhas não correspondem.');
    }
  };

  return (
    <div className='recovery_global_senha'>
      <div className="container_senha">
      <h1>Redefinir Senha</h1>
    

      <form onSubmit={handleSubmit} className="form_senha">
      <div className="form-group_senha">
        <label className="label_senha">Nova Senha:</label>
        <input
          className='input_senha'
          type="password"
          value={novaSenha}
          onChange={(e) => setNovaSenha(e.target.value)}
        />
        </div>
        <div className="form-group_senha">
        <label className="label">Confirmar Senha:</label>
        <input
          className='input_senha'
          type="password"
          value={confirmarSenha}
          onChange={(e) => setConfirmarSenha(e.target.value)}
        />
        </div>

        <button type="submit" className="button_senha">Redefinir Senha</button>
      </form>
    </div>
    </div>
  );
}

export default Recebimento;
