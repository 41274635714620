import { ResponsivePie } from "@nivo/pie";
import React, { useEffect, useState } from "react";
import axios from "axios";

const Pie = () => {
  const [chartData, setChartData] = useState([
    {
      id: "Doação de Alimentos",
      label: "Doação de Alimentos",
      value: 0,
      color: "hsl(90, 70%, 50%)",
    },
    {
      id: "Causa Animal",
      label: "Causa Animal",
      value: 0,
      color: "hsl(56, 70%, 50%)",
    },
    {
      id: "Reflorestamento",
      label: "Reflorestamento",
      value: 0,
      color: "hsl(26, 70%, 50%)",
    },
  ]);

  useEffect(() => {
    async function fetchData() {
      try {
        const interacoesResponse = await axios.get("https://www.repenseapi.com/api/v1/odds", {
          headers: {
            'api': process.env.REACT_APP_API_KEY
          }
        });
        const interacoes = interacoesResponse.data;

        const updatedChartData = chartData.map(data => ({
          ...data,
          value: interacoes.filter(interacao => interacao.causa_apoiada === data.label).length
        }));

        setChartData(updatedChartData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }

    fetchData();
  }, []); // Executa apenas uma vez ao montar o componente

  return (
    <ResponsivePie
      data={chartData}
      theme={{
        fontSize: "14px",
        fontFamily: "sans-serif",
      }}
      margin={{ top: 20, right: 85, bottom: 50, left: 85 }}
      innerRadius={0.5}
      colors={{ scheme: "category10" }}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
    />
  );
};

export default Pie;