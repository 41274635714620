import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header/Header';
import { Link } from 'react-router-dom';
import '../ChamadasTecnicos/ChamadasTecnicos.css';
import { jwtDecode } from 'jwt-decode';


const ChamadasTecnicos = () => {
  //////////   ALGUMAS CONSTANTES //////////
  const [chamadas, setChamadas] = useState([]);
  ////////////////////////////////////////////////
  const [tecnicos, setTecnicos] = useState([]); // State to store technicians
  const [showFinalizados, setShowFinalizados] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  //////////      //////         //////////
  const [userRole, setUserRole] = useState(null);
  const [UserEcoId, setUser] = useState(null);
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUser(decodedToken.sub); //ID do userEco que esta logado
        setUserRole(decodedToken.role); // 'role' é o campo do token que indica o papel do usuário
      } catch (error) {
        console.error('Erro ao decodificar o token: ', error);
      }
    }
  }, []);

//////////  REALIZAR A BUSCA NAS CHAMADAS E NOS TECNICOS //////////
  
  useEffect(() => { 

    // Fetch chamadas
    axios.get('https://www.repenseapi.com/api/v1/chamadas',{
      headers:{
        'api': process.env.REACT_APP_API_KEY
      }
    })
      .then(response => {
        setChamadas(response.data);
      })
      .catch(error => {
        console.error('Erro ao obter dados das chamadas:', error);
      });

    // Fetch tecnicos
    axios.get('https://www.repenseapi.com/api/v1/tecnicos',{
      headers:{
        'api': process.env.REACT_APP_API_KEY
      }
    })
      .then(response => {
        setTecnicos(response.data);
      })
      
      .catch(error => {
        console.error('Erro ao obter dados dos técnicos:', error);
      });

      axios.get('https://www.repenseapi.com/api/v1/chamadas',{
        headers:{
          'api': process.env.REACT_APP_API_KEY
        }
      })
      .then(response => {
        const chamadasData = response.data;
    
        // Verificar se existe alguma chamada ativa para o técnico
        const temChamadaAtiva = chamadasData.some(chamada => 
          chamada.tecnico && 
          chamada.tecnico.userEcoId === UserEcoId && 
          (chamada.status === 'Aguardando técnico' || chamada.status === 'Em andamento')
        );
    
        if (temChamadaAtiva) {
          localStorage.setItem('chamada', 'true'); // Salva indicador de chamada ativa no localStorage
        } else {
          localStorage.setItem('chamada', 'false'); // Atualiza ou limpa o indicador se não houver chamadas ativas
        }
      })
      .catch(error => {
        console.error('Erro ao obter dados das chamadas:', error);
      });
  }, [UserEcoId]); 

////////// FUNÇÃO PARA AJUSTAR FORMATO PASSADO PELO BACK //////////
  function formatDateTime(dateTimeStr) {
    if (dateTimeStr === null) {
      return ""; // Retorna uma string vazia quando a entrada é null
    }
  
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateTimeStr);
    const formattedDateTime = date.toLocaleDateString('pt-BR', options).replace(/\//g, '-') + ' ' ;
    return formattedDateTime;
  }
//////////  REALIZAR A BUSCA NAS CHAMADAS //////////

  const getTecnicoNomeById = (tecnicoId) => {
    const tecnico = tecnicos.find((tecnico) => tecnico.id === tecnicoId);
    return tecnico ? tecnico.nome : 'Não atribuído';
  };

  //////////  Atendimento / Encerramento //////////
  // Atualiza o status para "Em andamento" e define a data de atendimento
  const handleAtenderChamada = (chamadaId) => {
    const now = new Date().toISOString(); // Data e hora atual no formato ISO
  
    axios.patch(`https://www.repenseapi.com/api/v1/chamadas/${chamadaId}`, {
      status: "Em andamento",
      atendimento: now,
    }, {
      headers: {
        'api': process.env.REACT_APP_API_KEY// Substitua SUA_API_KEY_AQUI pela sua API Key real
      }
    })
    .then((response) => {
      // Atualizar o estado local para refletir as mudanças
      setChamadas(chamadas.map((chamada) => {
        if (chamada.id === chamadaId) {
          return { ...chamada, status: "Em andamento", atendimento: now };
        }
        return chamada;
      }));
    })
    .catch((error) => {
      console.error('Erro ao atualizar a chamada:', error);
    });
  };
    
  // Atualiza o status para "Finalizado" e define a data de encerramento
  const handleFinalizarChamada = (chamadaId) => {
    const now = new Date().toISOString(); // Data e hora atual no formato ISO
  
    axios.patch(`https://www.repenseapi.com/api/v1/chamadas/${chamadaId}`, {
      status: "Finalizado",
      encerramento: now,
    }, {
      headers: {
        'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key real
      }
    })
    .then((response) => {
      // Atualizar o estado local para refletir as mudanças
      setChamadas(chamadas.map((chamada) => {
        if (chamada.id === chamadaId) {
          return { ...chamada, status: "Finalizado", encerramento: now };
        }
        return chamada;
      }));
    })
    .catch((error) => {
      console.error('Erro ao atualizar a chamada:', error);
    });
  };

  //////////  REALIZAR A BUSCA NAS CHAMADAS //////////
  
  const filteredChamadas = chamadas.filter((chamada) =>
    chamada.maquina.nome.toLowerCase().includes(searchTerm) ||
    chamada.tipo.toLowerCase().includes(searchTerm) ||
    (chamada.tecnico && chamada.tecnico.nome.toLowerCase().includes(searchTerm))
  );
// Dentro do componente Sidebar, não em ChamadasTecnicos
useEffect(() => {
  const fetchChamadas = async () => {
    try {
      const response = await axios.get('https://www.repenseapi.com/api/v1/chamadas', {
        headers: {
          'api': process.env.REACT_APP_API_KEY
        }
      });
      const chamadasData = response.data;
      const chamadasAtivas = chamadasData.some(chamada => 
        chamada.status === 'Aguardando técnico' || chamada.status === 'Em andamento'
      );
      setTemChamadaAtiva(chamadasAtivas);
    } catch (error) {
      console.error('Erro ao buscar chamadas:', error);
    }
  };

  fetchChamadas();
}, []);


  
  return (
    <div>
    {userRole === 'tecnico' && ( 
    <div className="div-chamadas">
      <Sidebar />
      <div style={{ flex: '1' }}>
        <Header />
        <div className="padding-chamadas">
          <div className="nav_chamadas">
            <p className="titulo_chamadas">Lista de chamadas técnicos</p>
            <div className="navEndereco_chamadas">
              <Link exact to='/Painel_de_controle' className="home-redirect-chamadas"><p className="navHome_chamadas" >Home</p></Link>
              <p className="navAtual_chamadas" ></p>
              <p className="navAtual_chamadas">Chamadas</p>
            </div>
          </div>
          <div className="topCardChamadas">
            <input
                className='PesqChamadas'
                  type="text" 
                  placeholder="Pesquisar..." 
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
            />
          </div>
          <div className='Checkbox_Chamadas_div'>
              <input
                type="checkbox"
                checked={showFinalizados}
                onChange={() => setShowFinalizados(!showFinalizados)}
              />
              <p>Mostrar chamadas finalizadas</p>
          </div>

          <div className="Bodychamadas">
          
            <table className="TablechamadasStyle">
              <thead className="theadchamadas">
                <tr>
                  <th>Máquina</th>
                  <th>Alarme</th>
                  <th>Abertura</th>
                  <th>Atendimento</th>
                  <th>Encerramento</th>
                  <th>Responsável</th>
                  <th>Resposta de chamado</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody className="tbodyChamadas">
                {filteredChamadas
                  .filter((chamada) =>
                    showFinalizados ? true : chamada.status !== "Finalizado"
                  )
                  .filter((chamada) => chamada.tecnico && chamada.tecnico.userEcoId && chamada.tecnico.userEcoId === UserEcoId)
                  .map((chamada) => (
                    chamada.tecnicoId !== null && chamada.tecnicoId !== "Não atribuído" && (
                      <tr key={chamada.id}>
                        <td className="nome-Maquinas-chamadas">{chamada.maquina.nome}</td>
                        <td>{chamada.tipo}</td>
                        <td>{formatDateTime(chamada.createdAt)}</td>
                        <td>{formatDateTime(chamada.atendimento)}</td>
                        <td>{formatDateTime(chamada.encerramento)}</td>
                        <td>{getTecnicoNomeById(chamada.tecnicoId)}</td>
                        <td>
                          {chamada.status === "Aguardando técnico" && (
                            <button
                              className='btnAtenderChamadasTecnicos'
                              onClick={() => handleAtenderChamada(chamada.id)}
                            >
                              Atender
                            </button>
                          )}
                          {chamada.status === "Em andamento" && (
                            <button
                              className='btnEncerrarChamadasTecnicos'
                              onClick={() => handleFinalizarChamada(chamada.id)}
                            >
                              Finalizar
                            </button>
                          )}
                        </td>
                        <td className={chamada.status === "Em andamento" ? "status-andamento" : 
                                      chamada.status === "Finalizado" ? "status-finalizado" :
                                      chamada.status === "Aguardando técnico" || chamada.status === "Aguardando responsavel" ? "status-aguardando-tecnico" : ""}>
                          {chamada.status}
                        </td>
                      </tr>
                    )
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    )}
    </div>
  );
};

export default ChamadasTecnicos;