import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header/Header";
import "../EcoClub/createPatro.css";
import Back from "../../components/img/Voltar.png";
import { Link } from "react-router-dom";
import axios from "axios";

const CreateTec = () => {
  const [ceperror_tecnicos, setceperro_tecnicos] = useState(null);
  const [ ddderror_tecnicos, setddderror_tecnicos] = useState(null);
  const [nomeerror_tecnicos, setnomeerror_tecnicos] = useState(null);
  const [useroerror_tecnicos, setusererror_tecnicos] = useState(null);
  const [telefoneerror_tecnicos, settelefoneerror_tecnicos] = useState(null)
  
  const [formData, setFormData] = useState({
    // user:"",
    nome: "",
    logo: "",
    descricao: "",
    endereco: '',
    bairro: "",
    cep: "",
    cidade: "",
    estado: "",
    telefone: "",
    whatsapp: "",
    facebook: "",
    instagram: "",
    linkedin: "",
    youtube: "",
    site: "",
    email: "",
    created_at: "",
    contato: "",

    
    
  });
  const [errorEmailMessage, setErrorEmailMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(""); // Inicialmente, não há erro
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,

      [name]: value,
    });
    setErrorMessage('');
    setErrorEmailMessage('');
  
//jair-----------------------------------
  
    // fin jair ---------------------------------

    if (name === "DDD") {
      setddderror_tecnicos(null); // Limpa qualquer mensagem de erro ao digitar no campo CEP
      if (value.length !==2) {
        // Exibir mensagem de erro se o CEP não tiver 8 caracteres
        setddderror_tecnicos("O ddd deve ter 2 digitos.");
      }
    }
    if (name === "CEP") {
      setceperro_tecnicos(null); // Limpa qualquer mensagem de erro ao digitar no campo CEP
      if (value.length !==8) {
        // Exibir mensagem de erro se o CEP não tiver 8 caracteres
        setceperro_tecnicos("O CEP deve ter exatamente 8 digitos.");
      }
    }
    if (name === "user") {
      setusererror_tecnicos(null); // Limpa qualquer mensagem de erro ao digitar no campo CEP
      if (value.length <3) {
        // Exibir mensagem de erro se o CEP não tiver 8 caracteres
        setusererror_tecnicos("O usuario deve ter no minimo 3 caracteres.");
      }
    }
    if (name === "nome") {
      setnomeerror_tecnicos(null); // Limpa qualquer mensagem de erro ao digitar no campo CEP
      if (value.length < 3) {
        // Exibir mensagem de erro se o CEP não tiver 8 caracteres
        setnomeerror_tecnicos("O nome deve ter no minimo 3 caracteres.");
      }
    }
    if (name === "telefone") {
      settelefoneerror_tecnicos(null); // Limpa qualquer mensagem de erro ao digitar no campo CEP
      if (value.length !==9) {
        // Exibir mensagem de erro se o CEP não tiver 8 caracteres
        settelefoneerror_tecnicos("O telefone deve ter 9 digitos.");
      }
    }
  };

//   const handleUserEcoChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       userEco: {
//         ...prevData.userEco,
//         // password:formData.nome,
//         [name]: value,

//       },
//       // email: formData.userEco.email,
//       // user: formData.userEco.user,
      
      
//     }));
//     setErrorEmailMessage('');
//   };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    
    // const telefonoPattern = /^\d{9}$/;
    // if (!telefonoPattern.test(formData.telefone)) {
    //   setError("O número de telefone deve conter exatamente 9 dígitos numéricos.");
    //   return; // Impede a requisição para a API se a validação falhar
    // }
    try {
     
      const response = await axios.post("https://www.repenseapi.com/api/v1/empresas", {
        ...formData,
     }, {
        headers: {
           'api': process.env.REACT_APP_API_KEY
     }});
      
     
      if (response.status >= 200 && response.status < 300) {
        // Verifique se o código de status está dentro do intervalo de sucesso
        setError("");
        window.location.href = "/EcoClub";
      } else {
        // Error
        console.error("Erro ao cadastrar técnico:", response);
        if (response.data.error) {
          setError(response.data.error); // Exibe o erro da API para o usuário
        } else {
          setError("Erro ao cadastrar técnico. Por favor, tente novamente mais tarde.");
        }
      }
      console.log(response)
    } catch (error) {
      console.log(error);
      console.log('Error:', error.response.request.response);
      const errorData = error.response.data;
      // const emailError = errorData.errors;
      // Error connecting to API
      // console.error("Erro ao conectar com a API:", emailError.message);
      const errorResponse = error.response.request.response;
      const intento = JSON.parse(errorResponse);
      // console.log('Error1:',intento.message);
      // console.log('Error2:',intento.errors[0].message);
      setErrorEmailMessage(intento.message)
      setErrorMessage(intento.errors[0].message)
      
      // setError(error.message);
      setError("Erro ao conectar com a API. Por favor, verifique sua conexão ou tente novamente mais tarde.");
     }};

  return (
    <div className="div-createtec">
      <Sidebar />

      <div style={{ flex: "1" }}>
        <Header />
        <div className="padding-createtec">
          <div className="body_CreateTec">
          <Link exact={true ? "" : undefined} to="/EcoClub" id="BotaoVoltar_CreateTec">


              <img src={Back} alt="Botao-de-voltar" />
            </Link>
            <div className="card_CreateTec">
              <form onSubmit={handleSubmit}>
              <label className="txtStyle_CreateTec">nome <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="nome"
                  value={formData.nome}
                  onChange={handleChange}
                  placeholder="nome"
                  required
                />
                 {/* {useroerror_tecnicos && <p className="error-message">{useroerror_tecnicos}</p>} */}
                 
                <label className="txtStyle_CreateTec">logo <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="logo"
                  value={formData.logo}
                  onChange={handleChange}
                  placeholder="logo"
                  required
                />
                  {/* {nomeerror_tecnicos && <p className="error-message">{nomeerror_tecnicos}</p>} */}
                <label className="txtStyle_CreateTec">descricao <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="descricao"
                  value={formData.descricao}
                  onChange={handleChange}
                  placeholder="descricao"
                  required
                />
                {/* {errorEmailMessage && <p className="error-message">{errorEmailMessage}</p>} */}
                <label className="txtStyle_CreateTec">endereco <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="endereco"
                  value={formData.endereco}
                  onChange={handleChange}
                  placeholder="endereco"
                  required
                />

                <label className="txtStyle_CreateTec">bairro <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="bairro"
                  value={formData.bairro}
                  onChange={handleChange}
                  placeholder="bairro"
                  required
                />
                
                {/* {ceperror_tecnicos && <p className="error-message">{ceperror_tecnicos}</p>} */}
                <label className="txtStyle_CreateTec">cep <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="cep"
                  value={formData.cep}
                  onChange={handleChange}
                  placeholder="cep"
                  required
                />
                 {/* {ddderror_tecnicos && <p className="error-message">{ddderror_tecnicos}</p>} */}
                 
                
                {/* {error && <div className="error">{error}</div>} */}
                <label className="txtStyle_CreateTec">cidade <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="cidade"
                  value={formData.cidade}
                  onChange={handleChange}
                  placeholder="cidade"
                  required
                />
                <label className="txtStyle_CreateTec">estado <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="estado"
                  value={formData.estado}
                  onChange={handleChange}
                  placeholder="estado"
                  required
                />
                <label className="txtStyle_CreateTec">telefone <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="telefone"
                  value={formData.telefone}
                  onChange={handleChange}
                  placeholder="telefone"
                  required
                />
                <label className="txtStyle_CreateTec">whatsapp <p className='p_color'></p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="whatsapp"
                  value={formData.whatsapp}
                  onChange={handleChange}
                  placeholder="whatsapp"
                  required
                />
                <label className="txtStyle_CreateTec">facebook <p className='p_color'></p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="facebook"
                  value={formData.facebook}
                  onChange={handleChange}
                  placeholder="facebook"
                  required
                />
                <label className="txtStyle_CreateTec">instagram <p className='p_color'></p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="instagram"
                  value={formData.instagram}
                  onChange={handleChange}
                  placeholder="instagram"
                  required
                />
                <label className="txtStyle_CreateTec">linkedin <p className='p_color'></p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="linkedin"
                  value={formData.linkedin}
                  onChange={handleChange}
                  placeholder="linkedin"
                  required
                />
                <label className="txtStyle_CreateTec">youtube <p className='p_color'></p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="youtube"
                  value={formData.youtube}
                  onChange={handleChange}
                  placeholder="youtube"
                  required
                />
               
                 <label className="txtStyle_CreateTec">site <p className='p_color'></p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="site"
                  value={formData.site}
                  onChange={handleChange}
                  placeholder="site"
                  required
                />
                 <label className="txtStyle_CreateTec">email <p className='p_color'></p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="email"
                  required
                />
                 <label className="txtStyle_CreateTec">created_at <p className='p_color'></p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="created_at"
                  value={formData.created_at}
                  onChange={handleChange}
                  placeholder="AAAA-MM-DD"
                  required
                />
                 <label className="txtStyle_CreateTec">contato <p className='p_color'></p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="contato"
                  value={formData.contato}
                  onChange={handleChange}
                  placeholder="contato"
                  required
                />
                
                
                
                
                
                <div>
                {errorMessage && <div style={{ color: 'red' }} className="error">{errorMessage }
                {/* {errorEmailMessage && <p className="error-message">{errorEmailMessage}</p>} */}

                </div>} 
                    
                {/* <button className="buttonStyle_CreateTec" type="submit">    */}

                <button className="buttonStyle_CreateTec"  disabled={errorMessage !== '' & errorEmailMessage !== ''} onClick={handleSubmit}>
                  Cadastrar
                </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateTec;