import React, { useState } from 'react';
import axios from 'axios';
import './recovery.css'

function PasswordRecovery() {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://www.repenseapi.com/api/v1/auth/recovery', { email }, {
        headers: {
          'api': process.env.REACT_APP_API_KEY  // Substitua SUA_API_KEY_AQUI pela sua API Key real
        }
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  const handleTokenSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/change-password', { token, newPassword });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <div className='recovery_global'>
    <div className="container">
    <div>
    
      
      <p>{message}</p>
      {!token ? (
        <form onSubmit={handleEmailSubmit} className="form">
            <p className="info-text">
              <strong className='strong_color'>Esqueceu a senha?</strong> Enviaremos um email com instruções de como recuperar sua senha.
            </p>
          <label className="label">Email:</label>
          <input type="email" className='input' value={email} onChange={(e) => setEmail(e.target.value)} />
          <button type="submit" className="button">Send Recovery Token</button>
        </form>
      ) : (
        <form onSubmit={handleTokenSubmit} className="form">
          <label>Token:</label>
          <input type="text" value={token} onChange={(e) => setToken(e.target.value)} />
          <label className="label">New Password:</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <button type="submit" className="button">Reset Password</button>
        </form>
      )}
    </div>
    </div>
    </div>
  );
}

export default PasswordRecovery;
