// import { userId } from '../../protect/routerprotect.jsx';

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./Header.css";
import { CgProfile} from 'react-icons/cg';
import LogoutButton from '../../Logout/Logout.jsx'
import { jwtDecode } from 'jwt-decode';
// import { userId } from '../../protect/routerprotect.jsx';

// import userId from '../../protect/routerprotect.jsx'

// const [userRole, setUserRole] = useState(null);

//  //Validar Role
//  useEffect(() => {
//   const token = localStorage.getItem('token');
//   if (token) {
//     try {
//       const decodedToken = jwtDecode(token);
//       setUserRole(decodedToken.id); // 'role' é o campo do token que indica o papel do usuário
//       console.log("id del usuario,", userRole)
//     } catch (error) {
//       console.error('Erro ao decodificar o token: ', error);
//     }
//   }
// }, []);


const Header = () => {
  const [userRole, setUserRole] = useState(null);
  const [userName, setUserName] = useState(null);
  //Validar Role
  useEffect(() => {
    const token = localStorage.getItem('token');
    
    // console.log("user,", userId)
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserRole(decodedToken.sub); // 'role' é o campo do token que indica o papel do usuário
        setUserName(decodedToken.name)
     
      } catch (error) {
        console.error('Erro ao decodificar o token: ', error);
      }
    }
  }, []);




  const isOpen = false; // Defina isOpen aqui ou passe como uma propriedade do componente
  
  return (
    <div id='header' className={isOpen ? "header-open" : ""}>
      <article>
        <ul className='ul_header'>
          <li style={{ marginRight: '30px'}}>

            
            <Link style={{color:'white', fontFamily: 'sans-serif', display:'flex'}} to="/home">
            <CgProfile style={{width:'24px', height:'24px', color:'white', paddingRight:'5px'}}/>
               <p style={{marginTop:'5px'}}>{userName}</p>
               
            </Link>
            <p> <LogoutButton/></p>
          </li>
        </ul>
    </article> 

  
    </div>
  );
}
export default Header;