import React, { useState, useEffect } from "react";
import { Link, useNavigate   } from "react-router-dom";
import '../Login/Login_Register.css';
import ticketsImage from "../../components/img/EM_Branc.png";
import thas from "../../components/img/zyro-image.png";




export const Login = (props) => {
  const [email, setemail] = React.useState('');
  const [pass,setpass]= React.useState('');
  
  const navigate = useNavigate();
  // const handleSubmit = (e) => {
  //     e.preventDefault();
  //     console.log(email);
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://www.repenseapi.com/api/v1/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key
        },
        body: JSON.stringify({ email, password: pass }),
      });
      if (response.ok) {
        const data = await response.json();
        // Aqui você pode redirecionar o usuário para a página de administração e salvar o token.
        localStorage.setItem('token', data.token); // Salva o token JWT no localStorage
        localStorage.setItem('reload', '1');
        navigate('/Administracao');
      } else {
        console.log('Credenciais inválidas');
      }
    } catch (error) {
      console.error('Erro de conexão', error);
    }
  }


    return (
        <div className="app_Login_global">
            <div className="box_Login"> 
                <h2>Login</h2>
                  
                <form className="login-form" onSubmit={handleSubmit}>
                    
                    <label className="login-form_label" htmlFor ="email">  Email   </label>
                    <input
                    value= {email}
                    onChange ={(e) => setemail(e.target.value)}
                    type="email" 
                    placeholder="example@gmail.com" 
                    className="login-form_label_input"
                    name="email"/>


                    <label className="login-form_label" htmlFor ="password"> Senha   </label>
                    <input 
                    value= {pass}
                    onChange ={(e) => setpass(e.target.value)}
                    type="password" 
                    placeholder="***************" 
                    className="login-form_label_input" 
                    name="password"/>

                    <button type="ssubmit" className="login-form__log_in" onClick={handleSubmit}> log in  </button>
                </form>
                

                <Link exat to = "/recovery" id="register" >
                <button className="box_Login_esqueceSenha"> Esqueceu sua senha?.</button> 
                </Link>
            </div>
            <p className="EcoMachLogin" >EcoMachine</p>
            <p className="RepenseLogin">Repense</p>
          </div>

        



    ) 
}

export default Login;