import { ResponsivePie } from "@nivo/pie";
import React, { useEffect, useState } from "react";
import axios from "axios";

const Pie = ({ selectedMachine }) => {
  const [chartData, setChartData] = useState([
    {
      id: "Celulares Aceitos",
      label: "Celulares Aceitos",
      value: 2,
      color: "hsl(90, 70%, 50%)",
    },
    {
      id: "Celulares Recusados",
      label: "Celulares Recusados",
      value: 2,
      color: "hsl(56, 70%, 50%)",
    },
  ]);
  const [machineId, setMachineId] = useState(null);

  useEffect(() => {
    async function fetchMachineId() {
      if (selectedMachine && selectedMachine !== "Geral") {
        try {
          const machinesResponse = await axios.get("https://www.repenseapi.com/api/v1/machine", {
            headers: {
              'api': process.env.REACT_APP_API_KEY // Substitua SUA_API_KEY_AQUI pela sua API Key real
            }
          });
          const machine = machinesResponse.data.find(m => m.nome === selectedMachine);
          if (machine) setMachineId(machine.id);
        } catch (error) {
          console.error("Error fetching machine data: ", error);
        }
      } else {
        // Se "Geral" for selecionado, resetar machineId para null para contabilizar todas as interações
        setMachineId(null);
      }
    }

    fetchMachineId();
  }, [selectedMachine]);

  useEffect(() => {
    async function fetchData() {
      try {
        const interacoesResponse = await axios.get("https://www.repenseapi.com/api/v1/interacoes", {
          headers: {
            'api': process.env.REACT_APP_API_KEY// Substitua SUA_API_KEY_AQUI pela sua API Key real
          }
        });
        const interacoes = interacoesResponse.data;
        

        // Se "Geral" for selecionado ou machineId for null, não filtra as interações por máquina
        let filteredInteracoes = interacoes;
        if (machineId !== null) {
          filteredInteracoes = interacoes.filter(interacao => interacao.maquinaId === machineId);
        }


        const finalizadas = filteredInteracoes.filter((interacao) => interacao.status_final === 1).length;
        const inacabadas = filteredInteracoes.filter((interacao) => interacao.status_final === 2).length;
        const total = finalizadas + inacabadas;

        const updatedChartData = [
          {
            id: "Celulares Aceitos",
            label: "Celulares Aceitos",
            value: total > 0 ? (finalizadas / total) : 0, // Convertido para porcentagem
            
          },
          {
            id: "Celulares Recusados",
            label: "Celulares Recusados",
            value: total > 0 ? (inacabadas / total) : 0, // Convertido para porcentagem
            
          },
        ];

        setChartData(updatedChartData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }

    fetchData();
  }, [machineId, chartData]); // Adicionado chartData como dependência para atualização correta dos dados

  return (
    <ResponsivePie
      data={chartData}
      theme={{
        fontSize: "16px",
        fontFamily: "sans-serif",
      }}
      margin={{ top: 20, right: 85, bottom: 50, left: 85 }}
      innerRadius={0.5}
      colors={{ scheme: "dark2" }}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
      valueFormat=">.0%"
      legends={[
        {
          anchor: 'top-left',
          direction: 'column', 
          justify: false,
          itemsSpacing: 5,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#000000',
          itemDirection: 'left-to-right',
          symbolSize: 18, 
          symbolShape: 'square',
          
        }]}

    />
  );
};

export default Pie;
