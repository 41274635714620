import React, { useEffect, useState } from 'react';
import Sidebar from '../../../components/Sidebar';
import  Header from '../../../components/Header/Header';
import "../Cadastro-maquinas/Cadastro-maquinas.css";
import Back from '../../../components/img/Voltar.png'
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
// ...importações e código anterior

function CadastroMaquina() {
 
  const [cepperror, setcepperror] = useState(null);
  const [ passworderror, setpassworderror] = useState(null)
  const [nameerror,setnameerror] = useState(null)
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nome:"",
    // numero:"",
    CEP:"",
    serial:"",
    
   
  });
  const [errorEmailMessage, setErrorEmailMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(""); // Inicialmente, não há erro

  const fetchGeolocation = async (cep) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${cep}&key=AIzaSyCn674e42nuGTkY8eJ-5spNmBErvtE41V8`;
   
    try {
      const response = await axios.get(url);
      if (response.data.status === 'OK') {
        const location = response.data.results[0].geometry.location;
        return { latitude: location.lat, longitude: location.lng };
      } else {
        setcepperror("CEP inválido");
        return { latitude: null, longitude: null }; // Valores padrão quando não encontramos o CEP
      }
    } catch (error) {
      console.error('Erro ao buscar geolocalização:', error);
      setcepperror("Erro ao verificar o CEP");
      return { latitude: null, longitude: null }; // Valores padrão em caso de erro na API
    }
  };

  const handleChange = (e) => {
    
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrorMessage('');
    setError(''); 

    if (name === "CEP") {
      setcepperror(null); // Limpa qualquer mensagem de erro ao digitar no campo CEP
      if (value.length !== 8) {
        // Exibir mensagem de erro se o CEP não tiver 8 caracteres
        setcepperror("O CEP deve conter exatamente 8 números.");
      } else {
        // Verifica o CEP usando a API do Google
        
        fetchGeolocation(value);
      }

    }
    if (name === "serial") {
      setpassworderror(null); // Limpa qualquer mensagem de erro ao digitar no campo CEP
      if (value.length < 8) {
        // Exibir mensagem de erro se o CEP não tiver 8 caracteres
        setpassworderror("O Serial deve conter no minimo 8 caracteres.");
      }
    }
    if (name === "nome") {
      setnameerror(null); // Limpa qualquer mensagem de erro ao digitar no campo CEP
      if (value.length < 3 || value.length >15) {
        // Exibir mensagem de erro se o CEP não tiver 8 caracteres
        setnameerror("O nome deve ter no minimo 3 caracteres e no máximo 15.");
      }
    }
  };
 
  
  const handleSubmit = async (e) => {
    console.log('responses');
    e.preventDefault();

    try {
      // console.log('response');
      const location = await fetchGeolocation(formData.CEP);
      let dataToSend = {
        ...formData,
      };
      console.log(dataToSend);
      if (location.latitude !== null && location.longitude !== null) {
        dataToSend = {
          ...dataToSend,
          Latitude: location.latitude.toString(),
          Longitude: location.longitude.toString(),
          
        };
   
      }
      const response = await axios.post("https://www.repenseapi.com/api/v1/machine", dataToSend, {
        headers: {
          'api': '0f11e73d-a405-45a7-b298-3066aa412dda' // Substitua SUA_API_KEY_AQUI pela sua API Key real
        }
      });
      // console.log(response);
      if (response.status >= 200 && response.status < 300) {
        // Verifique se o código de status está dentro do intervalo de sucesso
        setError("");
        navigate('/maquinas');
      } else {
        // Error
        console.error("Erro ao cadastrar maquina:", response);
        if (response.data.error) {
          setError(response.data.error); // Exibe o erro da API para o usuário
        } else {
          setError("Erro ao cadastrar técnico. Por favor, tente novamente mais tarde.");
        }
      }
    } catch (error) {

      // Error connecting to API
      console.log('Error:', error.response.request.response);
      const errorResponse = error.response.request.response;
      const intento = JSON.parse(errorResponse);
      // setErrorEmailMessage(intento.message)
      setErrorMessage(intento.errors[0].message)
      // console.error("Erro ao conectar com a API:", intento.errors[0].message);
      // setError("Erro ao conectar com a API. Por favor, verifique sua conexão ou tente novamente mais tarde.");

      if (error.response) {
        // A requisição foi feita e o servidor respondeu com um status fora do intervalo 2xx
        console.error('Erro de resposta:', error.response);
        // Supondo que o backend envia erros em um campo chamado 'message'
        setError(error.response.data.message || "Ocorreu um erro inesperado.");
      } else if (error.request) {
        // A requisição foi feita mas não houve resposta
        console.error('Erro de requisição:', error.request);
        setError("Não foi possível obter resposta do servidor.");
      } else {
        // Algo aconteceu na configuração da requisição que disparou um erro
        console.error('Erro:', error.message);
        setError(error.message);
      }
    }
   };

  return (
    <div className="divCadastroMaquinas">
      <Sidebar />
      <div style={{ flex: "1" }}>
        <Header />
        <div className="padding-maquinas">
          <div className='colorBackgroundCadastroMaquinas'>
            <Link exact to="/Maquinas" className="BotaoVoltar_Maquinas">
              <img src={Back} alt="Botao-de-voltar" />
            </Link>
            <div className='divContainerFormMachine'>
            <form className='formGroup' onSubmit={handleSubmit}>
              <label className="txtStyle_CreateTec">Nome <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="nome"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder='min 3 max 15'
                  required
                />
                 {nameerror && <p className="error-message_maquina">{nameerror}</p>}
                <br/>
                {/* <label className="txtStyle_CreateTec">Numero</label> */}
                {/* {error && <div className="error">{error}</div>} */}
                {/* <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="numero"
                  value={formData.numero}
                  onChange={handleChange}
                  placeholder='Numero'
                  required
                /> */}
                  <label className="txtStyle_CreateTec">CEP <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="CEP"
                  value={formData.CEP}
                  onChange={handleChange}
                  placeholder='12345678'
                  required
                  
                />

                 {cepperror && <p className="error-message_maquina">{cepperror}</p>}
                
                <label className="txtStyle_CreateTec">Serial <p className='p_color'>*</p></label>

                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="serial"
                  value={formData.serial}
                  onChange={handleChange}
                  placeholder='min-8'
                  required
                />
                {passworderror && <p className="error-message_maquina">{passworderror}</p>}

                <label className="txtStyle_CreateTec">Descrição <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="descricao"
                  value={formData.descricao}
                  onChange={handleChange}
                  placeholder=''
                  required
                />

                <div>
                {errorMessage && <div style={{ color: 'red' }} className="error">{errorMessage }
                
                </div>} 


                {/* {error && <div className="error">{error}</div>} */}
               
                {/* {passworderror && <p className="error-message_maquina">{passworderror}</p>} */}
                <button className="buttonStyle_CreateTec" disabled={errorMessage !== '' || cepperror !== null} onClick={handleSubmit}>
                  Cadastrar
                </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CadastroMaquina;