// LogoutButton.js ou dentro do seu componente Header.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Logout/Logout.css'
const LogoutButton = () => {
  const navigate = useNavigate();
  
  const handleLogout = () => {
    // Aqui você implementa a lógica de logout, por exemplo:
    // - Limpar o token de autenticação do localstorage
    // - Chamar uma API de logout se necessário
    // - Redirecionar o usuário para a tela de login
    localStorage.removeItem('token');
    localStorage.removeItem('chamada');
    navigate('/');
  };
  
  return (
    <button className='button_sair' onClick={handleLogout}>
        Sair
    </button>
  );
};

export default LogoutButton;
