import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header/Header";
import "../CreateTec/CreateTec.css";
import Back from "../../components/img/Voltar.png";
import { Link } from "react-router-dom";
import axios from "axios";

const CreateTec = () => {
  const [ceperror_tecnicos, setceperro_tecnicos] = useState(null);
  const [ ddderror_tecnicos, setddderror_tecnicos] = useState(null);
  const [nomeerror_tecnicos, setnomeerror_tecnicos] = useState(null);
  const [useroerror_tecnicos, setusererror_tecnicos] = useState(null);
  const [telefoneerror_tecnicos, settelefoneerror_tecnicos] = useState(null)
  
  const [formData, setFormData] = useState({
    // user:"",
    nome: "",
    genero: "",
    dataNacimento: "",
    // email: '',
    CEP: "",
    telefone: "",
    DDD: "",
    userEco: {
      email: '',
      password: '',
      user: '',
    },
    // password: ""
    
  });
  const [errorEmailMessage, setErrorEmailMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(""); // Inicialmente, não há erro
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,

      [name]: value,
    });
    setErrorMessage('');
    setErrorEmailMessage('');
  
//jair-----------------------------------
  
    // fin jair ---------------------------------

    if (name === "DDD") {
      setddderror_tecnicos(null); // Limpa qualquer mensagem de erro ao digitar no campo CEP
      if (value.length !==2) {
        // Exibir mensagem de erro se o CEP não tiver 8 caracteres
        setddderror_tecnicos("O ddd deve ter 2 digitos.");
      }
    }
    if (name === "CEP") {
      setceperro_tecnicos(null); // Limpa qualquer mensagem de erro ao digitar no campo CEP
      if (value.length !==8) {
        // Exibir mensagem de erro se o CEP não tiver 8 caracteres
        setceperro_tecnicos("O CEP deve ter exatamente 8 digitos.");
      }
    }
    if (name === "user") {
      setusererror_tecnicos(null); // Limpa qualquer mensagem de erro ao digitar no campo CEP
      if (value.length <3) {
        // Exibir mensagem de erro se o CEP não tiver 8 caracteres
        setusererror_tecnicos("O usuario deve ter no minimo 3 caracteres.");
      }
    }
    if (name === "nome") {
      setnomeerror_tecnicos(null); // Limpa qualquer mensagem de erro ao digitar no campo CEP
      if (value.length < 3) {
        // Exibir mensagem de erro se o CEP não tiver 8 caracteres
        setnomeerror_tecnicos("O nome deve ter no minimo 3 caracteres.");
      }
    }
    if (name === "telefone") {
      settelefoneerror_tecnicos(null); // Limpa qualquer mensagem de erro ao digitar no campo CEP
      if (value.length !==9) {
        // Exibir mensagem de erro se o CEP não tiver 8 caracteres
        settelefoneerror_tecnicos("O telefone deve ter 9 digitos.");
      }
    }
  };

  const handleUserEcoChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      userEco: {
        ...prevData.userEco,
        // password:formData.nome,
        [name]: value,

      },
      // email: formData.userEco.email,
      // user: formData.userEco.user,
      
      
    }));
    setErrorEmailMessage('');
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    
    const telefonoPattern = /^\d{9}$/;
    if (!telefonoPattern.test(formData.telefone)) {
      setError("O número de telefone deve conter exatamente 9 dígitos numéricos.");
      return; // Impede a requisição para a API se a validação falhar
    }
    try {
     
      const response = await axios.post("https://www.repenseapi.com/api/v1/tecnicos", {
        
         ...formData,}
         , {
          headers: {
             'api': process.env.REACT_APP_API_KEY
      }});
    
     
     
      if (response.status >= 200 && response.status < 300) {
        // Verifique se o código de status está dentro do intervalo de sucesso
        setError("");
        window.location.href = "/Tecnicos";
      } else {
        // Error
        console.error("Erro ao cadastrar técnico:", response);
        if (response.data.error) {
          setError(response.data.error); // Exibe o erro da API para o usuário
        } else {
          setError("Erro ao cadastrar técnico. Por favor, tente novamente mais tarde.");
        }
      }
      console.log(response)
    } catch (error) {
      console.log(error);
      console.log('Error:', error.response.request.response);
      const errorData = error.response.data;

      const errorResponse = error.response.request.response;
      const intento = JSON.parse(errorResponse);

      setErrorEmailMessage(intento.message)
      setErrorMessage(intento.errors[0].message)
      
      // setError(error.message);
      setError("Erro ao conectar com a API. Por favor, verifique sua conexão ou tente novamente mais tarde.");
     }};

  return (
    <div className="div-createtec">
      <Sidebar />

      <div style={{ flex: "1" }}>
        <Header />
        <div className="padding-createtec">
          <div className="body_CreateTec">
          <Link exact={true ? "" : undefined} to="/Tecnicos" id="BotaoVoltar_CreateTec">


              <img src={Back} alt="Botao-de-voltar" />
            </Link>
            <div className="card_CreateTec">
              <form onSubmit={handleSubmit}>
              
                 
                <label className="txtStyle_CreateTec">Nome <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="nome"
                  value={formData.nome}
                  onChange={handleChange}
                  placeholder="Nome"
                  required
                />
                  {nomeerror_tecnicos && <p className="error-message">{nomeerror_tecnicos}</p>}
               
               
              

                <label className="txtStyle_CreateTec">CEP <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="CEP"
                  value={formData.CEP}
                  onChange={handleChange}
                  placeholder="12345678"
                  required
                />
                
                {ceperror_tecnicos && <p className="error-message">{ceperror_tecnicos}</p>}
                <label className="txtStyle_CreateTec">DDD <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="DDD"
                  value={formData.DDD}
                  onChange={handleChange}
                  placeholder="53"
                  required
                />
                 {ddderror_tecnicos && <p className="error-message">{ddderror_tecnicos}</p>}
                <label className="txtStyle_CreateTec">Telefone <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="telefone"
                  value={formData.telefone}
                  onChange={handleChange}
                  placeholder="123456789"
                  required
                />
                {telefoneerror_tecnicos && <p className="error-message">{telefoneerror_tecnicos}</p>}
                
           
                <label className="txtStyle_CreateTec">Data de nascimento <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="dataNacimento"
                  value={formData.dataNacimento}
                  onChange={handleChange}
                  placeholder="AAAA-MM-DD"
                  required
                />
                <label className="txtStyle_CreateTec">Usuário <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="user"
                  value={formData.userEco.user}
                  onChange={handleUserEcoChange}
                  placeholder="usuário"
                  required
                />
                 {useroerror_tecnicos && <p className="error-message">{useroerror_tecnicos}</p>}
                 <label className="txtStyle_CreateTec">Email <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="email"
                  value={formData.userEco.email}
                  onChange={handleUserEcoChange}
                  placeholder="E-mail"
                  required
                />
                <label className="txtStyle_CreateTec">Senha <p className='p_color'>*</p></label>
                <input
                  className="inputPadrao_CreateTec"
                  type="text"
                  name="password"
                  value={formData.userEco.password}
                  onChange={handleUserEcoChange}
                  placeholder="Senha"
                  required
                />

                <label className="txtStyle_CreateTec">Gênero <p className='p_color'>*</p></label>
                
                <select
                  className="inputPadrao_CreateTec"
                  name="genero"
                  value={formData.genero}
                  onChange={handleChange}
                  required
                >
                  <option value="">Selecione o gênero</option>
                  <option value="masculino">Masculino</option>
                  <option value="feminino">Feminino</option>
                  
                  <option value="outro">Outro</option>
                </select> 
                
                <div>
                {errorMessage && <div style={{ color: 'red' }} className="error">{errorMessage }
                {errorEmailMessage && <p className="error-message">{errorEmailMessage}</p>}

                </div>} 

                <button className="buttonStyle_CreateTec"  disabled={errorMessage !== '' & errorEmailMessage !== ''} onClick={handleSubmit}>
                  Cadastrar
                </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateTec;