
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Administração from './pages/Administração copy/Administração';
import ProtectedRoute from './protect/routerprotect'
import Chamadas from "./pages/Chamadas/Chamadas";
import ChamadasTecnicos from './pages/ChamadasTecnicos/ChamadasTecnicos';
import EcoTV from "./pages/EcoTV/EcoTV";
import EcoClub from "./pages/EcoClub/EcoClub";
import EcoVideo from "./pages/EcoTV/EcoVideo/EcoVideo";
import Localização from "./pages/Localização/Localizacao";
import Maquinas from "./pages/Maquinas/Maquinas";
import Painel_de_controle from "./pages/Painel_de_controle/Painel_de_controle";
import Tecnicos from "./pages/Tecnicos/Tecnicos";
import Sobre from "./pages/Sobre/Sobre";
import Error404 from "./pages/404 error/error404";
import CreateTec from "../src/pages/CreateTec/CreateTec";
import CadastroMaquina from "./pages/Maquinas/Cadastro-maquinas/CadastroMaquina";
import Login  from "./pages/Login/login";
import PasswordRecovery from './pages/Recovery_pass/Recovery'
import Recebimento from './pages/recebimento/token';
import PublicRoute from './protect/public_route';
import AppWrapper from './components/app_wraper';
import CreatePatro from "./pages/EcoClub/createPatro";

export function AppRoutes(){
  return ( 
  
  <Router>
    <AppWrapper>
      <Routes>
        <Route path="/Painel_de_controle" element={<Painel_de_controle/>}/> 
        <Route path="/Administracao" element={
            <ProtectedRoute>
              <Administração/>
              </ProtectedRoute>
          }/>
        <Route path="/chamadas" element={<ProtectedRoute><Chamadas/> </ProtectedRoute>}/>
        <Route path="/chamadasTecnicos" element={<ChamadasTecnicos/>}/>
        <Route path="/createPatro" element={<ProtectedRoute><CreatePatro/></ProtectedRoute>}/>
        <Route path="/maquinas" element={<ProtectedRoute><Maquinas/></ProtectedRoute>}/>
        <Route path="/Tecnicos" element={<ProtectedRoute><Tecnicos/></ProtectedRoute>}/>
        <Route path="/localizacao" element={<ProtectedRoute><Localização/></ProtectedRoute>}/>
        <Route path="/ecotv" element={<ProtectedRoute><EcoTV/></ProtectedRoute>}/>
        <Route path="/EcoVideo" element={<ProtectedRoute><EcoVideo/></ProtectedRoute>}/>
        <Route path="/EcoClub" element={<ProtectedRoute><EcoClub/></ProtectedRoute>}/>
        <Route path="/about" element={<ProtectedRoute><Sobre/></ProtectedRoute>}/>
        <Route path="/CreateTec" element={<ProtectedRoute><CreateTec/></ProtectedRoute>}/>
        <Route path="/CadastroMaquina" element={<ProtectedRoute><CadastroMaquina/></ProtectedRoute>}/>
        <Route path="/" element={  <PublicRoute><Login/></PublicRoute>}/>
        <Route path='/recovery' element={<PasswordRecovery/>}/>
        <Route path='/recebimento' element={<Recebimento/>}/>
        
        {/* <Route path='/createPatro' element={<createPatro/>}/> */}
        <Route path="*" element={<Error404/>}/>

      </Routes>
      </AppWrapper>
  </Router>
  );
};



