import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header/Header";
import "./Painel_de_controle.css";
import { Doughnut } from "react-chartjs-2";
import logoEcoF from "../../components/img/EM_Azul.png"
import { Box, useTheme,  Typography } from "@mui/material";
import { tokens } from "../../theme";
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Chart, ArcElement, ChartType, Plugin } from 'chart.js'
import Piecesar from "../../components/piecesar2";
import { BsFillCheckCircleFill, BsFillExclamationCircleFill, BsBellFill, BsFillExclamationTriangleFill } from "react-icons/bs";
import axios from 'axios';
import { useState,useEffect  } from 'react';




const Painel_de_controle = () => {
  
  const [totens, setTotens] = useState([]);
  const [totensData, setTotensData] = useState({});
  const [numAtivas, setNumeroMaquinasAtivas] = useState(0); 
  const [numDesativadas, setNumeroMaquinasDesativadas] = useState(0);  


  const [chamadas, setChamadas] = useState([]);
  const [numeroChamadas, setNumeroChamadas] = useState(0);
  const [numChamadasAtivas, setNumeroChamadasAtivas] = useState(0); 
  const [numChamadasFinalizadas, setNumeroChamadasFinalizadas] = useState(0); 

  const [numChamadasMaquinasCheias, setNumChamadasMaquinasCheias] = useState(0);


  useEffect(() => {
    async function fetchChamadas() {
      try {
        const response = await axios.get("https://www.repenseapi.com/api/v1/chamadas", {
          headers: {
            'api': process.env.REACT_APP_API_KEY  // Substitua SUA_API_KEY_AQUI pela sua API Key real
          }
        });
        const chamadasData = response.data;
  
        // Definindo e utilizando chamadasAtivas dentro do mesmo escopo
        const chamadasAtivas = chamadasData.filter(chamada => chamada.status !== 'Finalizado');
        const chamadasFinalizadas = chamadasData.filter(chamada => chamada.status === 'Finalizado');
  
        setChamadas(chamadasData);
        setNumeroChamadas(chamadasData.length);
        setNumeroChamadasAtivas(chamadasAtivas.length);
        setNumeroChamadasFinalizadas(chamadasFinalizadas.length);
  
        // Filtrando chamadas de "Máquinas Cheias" não finalizadas
        const chamadasMaquinasCheias = chamadasAtivas.filter(chamada => chamada.tipo === "Máquina Cheia").length;
  
        setNumChamadasMaquinasCheias(chamadasMaquinasCheias);
  
      } catch (error) {
        console.error("Erro ao buscar as chamadas:", error);
      }
    }
  
    fetchChamadas();
  }, []);
  


  ////////// FUNÇÃO PARA AJUSTAR FORMATO PASSADO PELO BACK DE DATA //////////
  function formatDateTime(dateTimeStr) {
    if (dateTimeStr === null) {
      return ""; // Retorna uma string vazia quando a entrada é null
    }
  
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateTimeStr);
    const formattedDateTime = date.toLocaleDateString('pt-BR', options).replace(/\//g, '-') + ' ' ;
    return formattedDateTime;
  }

  useEffect(() => {
    async function fetchMaquinasEInteracoes() {
      try {
        // Busca todos os totens
        const responseTotens = await axios.get("https://www.repenseapi.com/api/v1/machine", {
          headers: {
            'api': process.env.REACT_APP_API_KEY  // Substitua SUA_API_KEY_AQUI pela sua API Key real
          }
        });
        setTotens(responseTotens.data);
        const numMachinesAtivos = responseTotens.data.filter(responseTotens => responseTotens.status === true).length;
        
        setNumeroMaquinasAtivas(numMachinesAtivos);
        // Busca todas as interações
        const responseInteracoes = await axios.get("https://www.repenseapi.com/api/v1/interacoes", {
          headers: {
            'api': process.env.REACT_APP_API_KEY  // Substitua SUA_API_KEY_AQUI pela sua API Key real
          }
        });
        const interacoes = responseInteracoes.data;
  
        // Conta interações por toten_id
        const interacoesPorToten = {};
        interacoes.forEach(interacao => {
          if (interacoesPorToten[interacao.toten_id]) {
            interacoesPorToten[interacao.toten_id] += 1;
          } else {
            interacoesPorToten[interacao.toten_id] = 1;
          }
        });
  
        // Determina quais totens estão cheios
        let cheios = 0;
        for (const toten of responseTotens.data) {
          if (interacoesPorToten[toten.id] > 150) {
            cheios += 1;
          }
        }
        setNumeroMaquinasDesativadas(cheios);
  
        // A lógica para máquinas ativas pode ser ajustada conforme necessário
        // setNumeroMaquinasAtivas(responseTotens.data.length - cheios);
  
      } catch (error) {
        console.error("Erro ao buscar as máquinas ou interações:", error);
      }
    }
  
    fetchMaquinasEInteracoes();
  }, []);

  
  return (
    <div className="div-painel">
      <Sidebar />

      <div style={{ flex: '1' }}>
        <Header />
        <div className="padding-painel">
          <div className="nav_painel">
            <p className="titulo_painel">Painel de Controle</p>

            <div className="navEndereco_painel">
              <p className="navHome_painel" >Home</p>
              <p className="navAtual_painel" > &gt; </p>
              <p className="navAtual_painel">Painel de Controle</p>
            </div>

          </div>
          <div className="Tabsuperior_painel">

            <div className="containersup_painel">
              <BsFillCheckCircleFill size={30} style={{ fill: '#139b60', position: "", marginRight: "80px", marginLeft:"20px", marginBottom: "45px" }} />
              <div className="informacaoCard_painel">
                <p className="cardNumber_painel"> {numAtivas} </p>
                <p className="submaqativas_painel">Máquinas ativas</p>
              </div>
            </div>
            <div className="containersup_painel">
              <BsFillExclamationCircleFill size={30} style={{ fill: '#FF0000', position: "", marginRight: "80px", marginLeft:"20px", marginBottom: "45px" }}/>
              <div className="informacaoCard_painel" >
                <p className="cardNumber_painel"> {numChamadasMaquinasCheias}</p>
                <p className="submaqcheias_painel">Máquinas cheias</p>
              </div>
              
            </div>

            <div className="containersup_painel">
              <BsBellFill size={30} style={{ fill: '#139b60', position: "", marginRight: "80px", marginLeft:"20px", marginBottom: "45px" }}/>
              <div className="informacaoCard_painel">
                <p className="cardNumber_painel"> {numChamadasFinalizadas}</p>
                <p className="subalarmativas_painel">Chamadas finalizadas</p>
              </div>
              
            </div>
            <div className="containersup_painel">
              <BsFillExclamationTriangleFill size={30} style={{ fill: '#139b60', position: "", marginRight: "80px", marginLeft:"20px", marginBottom: "45px" }}/>
              <div className="informacaoCard_painel">
                <p className="cardNumber_painel"> {numChamadasAtivas}</p>
                <p className="subchmativas_painel">Chamadas ativas</p>
              </div>
              
            </div>
          </div>
          <div className="Tabinferior_painel">
            <div className="graf_fun_painel">
              <h2 className="TitlePizzaPainel">Funcionamento EcoMachine</h2>
              <Piecesar />
            </div>
                    
            <div className="graf_fun_painel_alarm">
              <h2>Chamadas Finalizadas</h2>

              <Table id="" className="table_painelStyle">
                  <TableHead>
                    <TableRow > 
                      <TableCell>Tecnico</TableCell>
                      <TableCell>Máquina</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>Alarme</TableCell>
                    </TableRow> 
                  </TableHead>
                  <TableBody className="tbodypainel">
                    {chamadas
                      .filter((chamada) => chamada.status === 'Finalizado') // Filtra as chamadas com status 'finalizado'
                      .map((chamada, index) => (
                        <TableRow key={index}>
                          <TableCell>{chamada.tecnico.id ? chamada.tecnico.nome : ''}</TableCell>
                          <TableCell>{chamada.maquina ? chamada.maquina.nome : '' }</TableCell>
                          <TableCell>{formatDateTime(chamada.createdAt)}</TableCell>
                          <TableCell>{chamada.tipo}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
            </div>

          </div>
        </div>
      </div>

    </div>
  );
};

export default Painel_de_controle;
