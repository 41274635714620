import React, { useState, useEffect } from "react";
import '../Maquinas/modal.css'
import axios from 'axios';

function Modal_maquinas({ isOpen, onClose, machineToEdit, onUpdateMachine}) {
  const [editedMachine, setEditedMachine] = useState({ ...machineToEdit });
  const [ceperror_maquinas, setceperror_maquinas] = useState(null);
  const [nomerror, setnomeerror] = useState(null)
  const [serialerror, setserialerror] = useState(null)
  const [discricaoerror, setdiscricaoerror] = useState (null)
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "CEP") {
      setceperror_maquinas(null); // Limpa qualquer mensagem de erro ao digitar no campo CEP
      if (value.length !== 8) {
        // Exibir mensagem de erro se o CEP não tiver 8 caracteres
        setceperror_maquinas("O CEP deve conter exatamente 8 números.");
      }
    }
    if (name ==='nome'){
      setnomeerror(null);
      if (value.length <3 || value.length >15){
        setnomeerror('O nome deve conter no minmo 3 caracteres e no máximo 15.')
      }

    }
 
    if(name === 'descricao' ){
      setdiscricaoerror(null);
      if (value.length === 0){
        setdiscricaoerror('A discrição não pode estar vazia.')
      }
    }
    if (name ==='serial'){
      setserialerror(null)
      if (value.length <8){
        setserialerror('O serial tem que ter mais de 8 caracteres.')
      }
    }
    setEditedMachine({ ...editedMachine, [name]: value });
    setErrorMessage('');
  };
  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    if (name === "CEP") {
      setceperror_maquinas(null); // Limpa qualquer mensagem de erro ao digitar no campo CEP
      if (value.length !== 8) {
        // Exibir mensagem de erro se o CEP não tiver 8 caracteres
        setceperror_maquinas("O CEP deve conter exatamente 8 números.");
      }
    }
    
    setEditedMachine({ ...editedMachine, [name]: value });
  };
  
  // Este useEffect também parece desnecessário, pois faz a mesma coisa que o useState anterior.
  // Sugiro removê-lo.
  useEffect(() => {
    if (machineToEdit) {
      setEditedMachine({ ...machineToEdit });
    }
  }, [machineToEdit]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    
    console.log('editedMachine:', editedMachine);

    if (editedMachine.id !== undefined && editedMachine.id !== null) {
      const machineDataToUpdate = {
        nome: editedMachine.nome,
        // numero: editedMachine.numero,
        serial: editedMachine.serial,
        CEP: editedMachine.CEP,
        status: editedMachine.status,
        descricao: editedMachine.descricao
    
      };

      try {
        const response = await axios.patch(
          `https://www.repenseapi.com/api/v1/machine/${editedMachine.id}`,
          machineDataToUpdate
          , {
            headers: {
              'api': process.env.REACT_APP_API_KEY  // Substitua SUA_API_KEY_AQUI pela sua API Key real
            }
          });

        if (response.status === 200) {
          onClose(); // Fecha o modal
          if (typeof onUpdateMachine === 'function') {
            onUpdateMachine(editedMachine); // Atualiza a máquina
          }
          window.location.reload(); // Recarrega a página
        } else {
          console.error('Erro ao atualizar a máquina:', response.statusText);
          onClose();
        }
      } catch (error) {
        console.log('Error:', error.response.request.response);
        const errorResponse = error.response.request.response;
        const intento = JSON.parse(errorResponse);
        // setErrorEmailMessage(intento.message)
        setErrorMessage(intento.errors[0].message)
        console.error('Erro ao atualizar a máquina:', error);
        
      }
    } else {
      console.error('ID da máquina não está definido ou é inválido.');
      onClose();
    }
  };

  return (
    <div className={`modal ${isOpen ? 'open' : 'closed'}`}>
      <div className="modal-content">
        <h2 className="h2_content">Editar Máquina</h2>
        <form onSubmit={handleSubmit}>
          <label className="label_modal">
          <p className="p_modal">Nome:</p>
            <input
              className="input_modal"
              type="text"
              name="nome"
              value={editedMachine.nome}
              onChange={handleInputChange}
            />
            {nomerror && <p className="error-message">{nomerror}</p>}
          </label>
          {/* <label className="label_modal">
          <p className="p_modal">número:</p>
            <input
              className="input_modal"
              type="text"
              name="numero"
              value={editedMachine.numero}
              onChange={handleInputChange}
            />
            
          </label> */}
          <label className="label_modal">
          <p className="p_modal">Serial:</p>
            <input 
              className="input_modal"
              type="text"
              name="serial"
              value={editedMachine.serial}
              onChange={handleInputChange}
            />
             {serialerror && <p className="error-message">{serialerror}</p>}
          </label>
          <label className="label_modal">
          <p className="p_modal">CEP:</p>
            <input 
              className="input_modal"
              type="text"
              name="CEP"
              value={editedMachine.CEP}
              onChange={handleInputChange}
            />
            {ceperror_maquinas && <p className="error-message">{ceperror_maquinas}</p>}
          </label>
          <label className="label_modal">
  <p className="p_modal">Status:</p>
  <select
    className="input_modal"
    name="status"
    value={editedMachine.status}
    onChange={handleInputChange}
  >

    <option value={true}>Ativa</option>
    <option value={false}>Inativa</option>
  </select>
</label>

          <label className="label_modal">
          <p className="p_modal">Descrição:</p>
            <input 
              className="input_modal"
              type="text"
              name="descricao"
              value={editedMachine.descricao}
              onChange={handleInputChange}
            />
            {discricaoerror && <p className="error-message">{discricaoerror}</p>}
            {errorMessage && <div style={{ color: 'red' }} className="error">{errorMessage }
                
            </div>} 
          </label>
          
          <button className="button_modal_save" disabled={errorMessage !== ''} onClick={handleSubmit}>Salvar</button>
          <button className="button_modal_cancel" onClick={onClose}>Cancelar</button>
        </form>
      </div>
    </div>
  );
}

export default Modal_maquinas;
