import React, { useState, useEffect } from "react";
// import '../CreateTec/modal_tecnicos.css'
import '../EcoClub/modal_club.css'
import axios from 'axios';
function Modal_Eco({ isOpen, onClose, tecnicoToEdit, onUpdateTecnico }) {
  const [editedTecnico, setEditedTecnico] = useState({ ...tecnicoToEdit });
  const [cepError, setCepError] = useState(null);
  const [telefoneerror, telefoneError] = useState(null);
  const [ddderror, setddderror] = useState(null);
  const [errorEmailMessage, setErrorEmailMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  // Este useEffect também parece desnecessário, pois faz a mesma coisa que o useState anterior.
  // Sugiro removê-lo.
  useEffect(() => {
    if (tecnicoToEdit) {
      setEditedTecnico({ ...tecnicoToEdit });
    }
  }, [tecnicoToEdit]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    console.log('editedTecnicos:', editedTecnico);
  
    if (editedTecnico.id !== undefined && editedTecnico.id !== null) {
      const tecnicoDataToUpdate = {
        nome: editedTecnico.nome,
        telefone: editedTecnico.telefone,
        endereco: editedTecnico.endereco,
        bairro: editedTecnico.bairro,
        cep: editedTecnico.cep,
        cidade: editedTecnico.cidade,
        whatsapp: editedTecnico.whatsapp,
        instagram: editedTecnico.instagram,
        linkedin: editedTecnico.linkedin,
        site: editedTecnico.site,
        contato: editedTecnico.contato,
        email: editedTecnico.email,
       

      };
  
      try {
        const response = await axios.patch(
            `https://www.repenseapi.com:3001/api/v1/tecnicos/${editedTecnico.id}`,
          tecnicoDataToUpdate,{
            headers:{
              'api' : process.env.REACT_APP_API_KEY
            }
          }
        );
  
        if (response.status === 200) {
          onClose();
          // Certifique-se de que onUpdateTecnico seja uma função válida
          if (typeof onUpdateTecnico === 'function') {
            onUpdateTecnico(editedTecnico);
          }
        } else {
          console.error('Erro ao atualizar a máquina:', response.statusText);
          onClose();
        }
      } catch (error) {
            const errorData = error.response.data;
            
            // const emailError = errorData.errors;
            // Error connecting to API
            // console.error("Erro ao conectar com a API:", emailError.message);
            const errorResponse = error.response.request.response;
            const intento = JSON.parse(errorResponse);
            console.log('Error14:',error.response);
            console.log('Error1:',intento.message);
            console.log('Error2:',intento.errors[0].message);
            setErrorEmailMessage(intento.message)
            setErrorMessage(intento.errors[0].message)

            // console.error('Erro ao atualizar a máquina:', error);
      }
    } else {
      console.error('ID da máquina não está definido ou é inválido.');
    }
  };
  return (
    <div className={`modal_tec ${isOpen ? 'open' : 'closed'}`}>
      <div className="modal-content_tec">
        <h2 className="h2_content_tec">EcoClub</h2>
        <form onSubmit={handleSubmit}>
          <label className="label_modal_tec">
          <p className="p_modal_tec">Nome:</p>
            <input
              className="input_modal_tec"
              type="text"
              name="nome"
              value={editedTecnico.nome}
            />
          </label>
          <label className="label_modal_tec">
          <p className="p_modal">email:</p>
            <input
              className="input_modal_tec"
              type="text"
              name="email"
              value={editedTecnico.email|| "Não tem"}
            />
          </label>

            {/* Exibir mensagem de erro do CEP se houver um erro */}
            {cepError && <p className="error-message">{cepError}</p>}
          <label className="label_modal_tec">
            <p className="p_modal">cep:</p>
            <input 
              className="input_modal_tec"
              type="text"
              name="DD"
              value={editedTecnico.cep}
            />
          </label>
          { ddderror && <p className="error-message">{ddderror}</p>}
          <label className="label_modal_tec">
            <p className="p_modal">whatsapp:</p>
            <input 
              className="input_modal_tec"
              type="text"
              name="telefono"
              value={editedTecnico.whatsapp|| "Não tem"}
            />
          </label>
          <label className="label_modal_tec">
            <p className="p_modal">site:</p>
            <input 
              className="input_modal_tec"
              type="text"
              name="telefono"
              value={editedTecnico.site|| "Não tem"}
            />
          </label>
         
          <label className="label_modal_tec">
            <p className="p_modal">instagram:</p>
            <input 
              className="input_modal_tec"
              type="text"
              name="telefono"
              value={editedTecnico.instagram|| "Não tem"}
            />
          </label>
          <label className="label_modal_tec">
            <p className="p_modal">linkedin:</p>
            <input 
              className="input_modal_tec"
              type="text"
              name="telefono"
              value={editedTecnico.linkedin|| "Não tem"}
            />
          </label>
          <button className="button_modal_cancel_tec" onClick={onClose}>Pronto!</button>
        </form>
      </div>
    </div>
  );
}

export default Modal_Eco;